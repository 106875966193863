import { useSceneInitializer } from '~/src/hooks/scene'

const PlayerViewport = () => {
  const canvasRef = useSceneInitializer()
  return (
    <div className="player-viewport">
      <canvas id="player-viewport" ref={canvasRef} />
    </div>
  )
}

export default PlayerViewport
