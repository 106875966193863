import { makeAutoObservable } from 'mobx'
import toaster from '~/src/features/toaster/toaster'
import { assign, isEmpty } from 'lodash'

import { toJS } from 'mobx'

const UNDO_TRANSITION_DURATION = 200

class UndoStore {
  activeSnapshot = null
  previousSnapshots = []
  nextSnapshots = []

  constructor(rootStore) {
    makeAutoObservable(this, {})
    const { animator, appState, training, transitions, toolbar } = rootStore
    assign(this, { animator, appState, training, transitions, toolbar })
  }

  reset() {
    this.activeSnapshot = null
    this.previousSnapshots = []
    this.nextSnapshots = []
  }

  getSnapshot(message) {
    const training = this.training.toJSON()
    const appState = this.appState.serialize()
    return { training, appState, message }
  }

  saveSnapshot(message) {
    const snapshot = this.getSnapshot(message)
    this.nextSnapshots = []
    this.activeSnapshot = null
    this.previousSnapshots.push(snapshot)
    // console.log("LEN:>", this.previousSnapshots.length)
  }

  discardSnapshot() {
    this.previousSnapshots.pop()
  }

  restoreSnapshot({ training, appState, message }) {
    this.toolbar.unselectNode()
    this.training.readJSON(training)
    this.appState.deserialize(appState)
    this.transitions.onSelectedStepChange(appState.selectedStepId)
    // reduce the transition duration to make the undo snappy
    const savedDuration = this.training.transitionDuration
    this.training.transitionDuration = UNDO_TRANSITION_DURATION
    this.appState.selectedStepId = appState.selectedStepId
    if (message)
      toaster.show({
        intent: 'warning',
        icon: 'undo',
        message: message,
        timeout: 1500,
      })
    // restore the original transition duration
    this.animator.runAfterTransition(() => {
      this.training.transitionDuration = savedDuration
    })
  }

  restorePreviousSnapshot = () => {
    if (!this.canUndo()) return
    this.nextSnapshots.push(this.activeSnapshot || this.getSnapshot())
    const snapshot = this.previousSnapshots.pop()
    this.activeSnapshot = snapshot
    this.restoreSnapshot(snapshot)
    this.appState.updateStepThumbnail()
  }

  restoreNextSnapshot = () => {
    if (!this.canRedo()) return
    if (!this.activeSnapshot) throw new Error('fuuuuuuuu...')
    this.previousSnapshots.push(this.activeSnapshot)
    const snapshot = this.nextSnapshots.pop()
    this.activeSnapshot = snapshot
    this.restoreSnapshot(snapshot)
    this.appState.updateStepThumbnail()
  }

  canUndo() {
    return !isEmpty(this.previousSnapshots)
  }

  canRedo() {
    return !isEmpty(this.nextSnapshots)
  }
}

export default UndoStore
