import { env } from '../env'

export async function getDistributionData(token, password) {
  const response = await fetch(`${env.contentAPI}/distribution/${token}`, {
    method: 'POST',
    body: JSON.stringify({ password: password }),
  })
  try {
    const data = await response.json()
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export async function isDistributionProtected(token) {
  const response = await fetch(`${env.contentAPI}/isProtected/${token}`)
  const data = await response.json()
  return data.isProtected
}
