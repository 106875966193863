import { handleKeyDown, loadKeybindings } from './keybindings'

export function addInputListeners(rootStore) {
  loadKeybindings()
  document.addEventListener('keydown', handleKeyDown)
  window.addEventListener('contextmenu', function (e) {
    e.preventDefault()
  })
  window.onbeforeunload = e => {
    if (
      rootStore.appState.unsavedChanges &&
      rootStore.appState.mode === 'editor'
    ) {
      e.preventDefault()
      e.returnValue = ''
    } else {
      delete e['returnValue']
    }
  }
}

export function removeInputListeners() {
  document.removeEventListener('keydown', handleKeyDown)
}
