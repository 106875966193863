import ReactDOM from 'react-dom'
import rootStore from '~/src/app/store'
import { initializeBackend } from '~/src/features/backend'
import App from './App'

const main = async () => {
  await initializeBackend({
    credentialsProvider: rootStore.auth.currentCredentials,
  })
  render()
}

const render = () => {
  const app = document.getElementById('app')
  ReactDOM.render(<App />, app)
}

main()
