import rootStore from '~/src/app/store'
import { TOOL_MODE } from '~src/features/toolbar/store'
import PositionTool from '~/src/features/tools/position/positionTool'
import RotationTool from '~/src/features/tools/rotation/rotationTool'
import ScaleTool from '~/src/features/tools/scale/scaleTool'
import HighlightTool from '~/src/features/tools/highlight/highlightTool'
import LabelTool from '~/src/features/tools/label/labelTool'
import RemoveTool from '~/src/features/tools/remove/removeTool'

let keyDownHandlers = []

// ----- interface

// @TODO: I think it makes more sense to have commands at the application level that do this
// operations. Keybindings would then target a command. They could be shared with the GUI.

export function loadKeybindings() {
  const { toolbar, undo, appState, persistence } = rootStore
  keyDownHandlers = []

  // toolbar
  keyDown('KeyW', [toolMode], () => toolbar.activateTool(PositionTool))
  keyDown('KeyR', [toolMode, not(Ctrl)], () =>
    toolbar.activateTool(RotationTool),
  )
  keyDown('KeyS', [toolMode, not(Ctrl)], () => toolbar.activateTool(ScaleTool))
  keyDown('KeyH', [toolMode], () => toolbar.activateTool(HighlightTool))
  keyDown('KeyA', [toolMode], () => toolbar.activateTool(LabelTool))
  keyDown('KeyX', [Ctrl, toolMode], () => toolbar.activateTool(RemoveTool))

  // editor
  keyDown('KeyZ', [Ctrl, editor], () => undo.restorePreviousSnapshot())
  keyDown('KeyY', [Ctrl, editor], () => undo.restoreNextSnapshot())
  keyDown('KeyS', [Ctrl, editor], () => persistence.saveTraining())
  keyDown('KeyM', [editor], () => {
    toolbar.unselectNode()
    appState.sidebarDrawer =
      appState.sidebarDrawer === 'add-model' ? undefined : 'add-model'
  })

  // global
  keyDown('Enter', [Ctrl], () => {
    toolbar.unselectNode()
    if (appState.mode === 'editor') appState.previewMode()
    else appState.editorMode()
  })
}

export function handleKeyDown(ev) {
  const handler = keyDownHandlers.find(
    handler =>
      handler.code === ev.code &&
      handler.predicates.reduce((acc, p) => p(ev) && acc, true),
  )
  if (handler) {
    if (!['TEXTAREA', 'INPUT'].includes(document.activeElement.nodeName)) {
      handler.handler(ev)
      ev.preventDefault()
    }
  }
}

// ----- utils

const toolMode = _ =>
  rootStore.toolbar.selectedNode && rootStore.toolbar.toolbarMode === TOOL_MODE

const editor = _ => rootStore.appState.mode === 'editor'

const Ctrl = ev => ev.ctrlKey

const not = predicate => ev => !predicate(ev)

const keyDown = (code, predicates, handler) =>
  keyDownHandlers.push({ code, predicates, handler })
