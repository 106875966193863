import { assign } from 'lodash'

export const previousElement = (list, element, defaultValue = 0) => {
  const idx = list.indexOf(element)
  return idx >= 1 ? list[idx - 1] : defaultValue
}

export const closestElement = (list, element) => {
  const idx = list.indexOf(element)
  return idx === 0 ? list[1] : list[idx - 1]
}

export const createPromise = () => {
  let resolve, reject
  const promise = new Promise((_resolve, _reject) => {
    ;(resolve = _resolve), (reject = _reject)
  })
  assign(promise, { resolve, reject })
  return promise
}

export const wait = ms => {
  const promise = createPromise(0)
  setTimeout(promise.resolve, ms)
  return promise
}

export const coerce = (value, min, max) => {
  return Math.min(max, Math.max(min, value))
}
