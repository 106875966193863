import { Color3, Vector3 } from '@babylonjs/core/Maths/math'
import '@babylonjs/core/Materials/standardMaterial'
import '@babylonjs/core/Meshes/meshBuilder'

import floorTexture from '~/assets/textures/floor.png'
import '@babylonjs/core/Layers/effectLayerSceneComponent'

const initializeScene = sceneManager => {
  const { camera, scene, canvas } = sceneManager
  scene.clearColor = new Color3(1, 1, 1)
  scene.collisionsEnabled = false
  camera.setPosition(new Vector3(5, 3, -5))
  camera.setTarget(Vector3.Zero())
  camera.wheelPrecision = 50
  camera.allowUpsideDown = false
  camera.minZ = 0.1
  camera.lowerRadiusLimit = 0.1
  camera.upperRadiusLimit = 15
  // camera.wheelDeltaPercentage = true
  // camera.panningAxis = new Vector3(1, 0, 1)
  camera.checkCollisions = false
  camera.attachControl(canvas, true)
  // light
  // const light = new HemisphericLight("light1", new Vector3(0, 1, 0), scene)
  // light.intensity = 2.8
  scene.createDefaultLight()
  scene.physicsEnabled = false

  // TODO!

  const helper = scene.createDefaultEnvironment({
    enableGroundShadow: false,
    groundTexture: floorTexture,
    groundColor: Color3.White(),
    groundYBias: 0.01,
    groundSize: 15,
    createSkybox: false,
  })
  helper.ground.isPickable = false
  sceneManager.ground = helper.ground

  window.scene = scene
}

export default initializeScene
