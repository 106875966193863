import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FormGroup, Slider, H4, Switch } from '@blueprintjs/core'
import Thumbnail from './Thumbnail'
import { useStore } from '~/src/app/store'

const SettingsPanel = () => {
  const { training } = useStore()
  return (
    <div className="settings-panel">
      <H4>Visualization settings</H4>
      <Thumbnail />
      <H4>Transition duration</H4>
      <FormGroup>
        <Slider
          className="slider"
          min={0.1}
          max={2}
          stepSize={0.1}
          onChange={action(v => (training.transitionDuration = v * 1000))}
          labelValues={[]}
          labelRenderer={v => `${v.toFixed(1)}s`}
          value={training.transitionDuration / 1000}
          intent="primary"
        />
        <Switch
          label="Auto-play steps"
          checked={training.autoPlay}
          onChange={action(e => (training.autoPlay = e.target.checked))}
          large
          alignIndicator="right"
        />
      </FormGroup>
    </div>
  )
}

export default observer(SettingsPanel)
