import { action } from 'mobx'
import rootStore from '~/src/app/store'
import { Color3 } from '@babylonjs/core/Maths/math'
import { assign, get } from 'lodash'

// debug
import { toJS } from 'mobx'

class HighlightTool {
  key = 'highlight'

  attachToScene(node, state) {
    assign(this, { node, state })
    const { sceneManager } = rootStore
    sceneManager.unhighlight(node)
    // const { sceneManager } = rootStore
    // sceneManager.unhighlight(node)
  }

  dettachFromScene(node, _state) {
    const { sceneManager } = rootStore
    sceneManager.highlightManager.clearHighlightHistory(node)
    if (this.state.active) {
      const color = Color3.FromHexString(this.state.color)
      sceneManager.highlight(node, color)
    }
    sceneManager.highlight(node)
    node.overlayAlpha = 0.5
    assign(this, { node: null, state: null })
  }

  // ui

  clearHighlight = action(() => {
    const { sceneManager, undo } = rootStore
    undo.saveSnapshot()
    sceneManager.highlightManager.clearHighlightHistory(this.node)
    sceneManager.unhighlight(this.node)
    this.state.active = false
    this.state.color = null
  })

  getHighlightColor() {
    return get(this.state, ['color'])
  }

  setHighlightColor = action(hexColor => {
    const { sceneManager, undo } = rootStore
    undo.saveSnapshot()
    sceneManager.highlightManager.clearHighlightHistory(this.node)
    sceneManager.unhighlight(this.node)
    sceneManager.highlight(this.node, Color3.FromHexString(hexColor))
    // no one liked this effect (flattening the colors, removing the volume shadows)
    // this.node.overlayAlpha = 1
    this.state.active = true
    this.state.color = hexColor
  })

  // application

  onBeforeTransition(node, _originState = {}, targetState = {}, refState) {
    const { sceneManager } = rootStore
    if (targetState.active) {
      if (!refState.wasHighlighted) {
        node.overlayAlpha = 0
      }
      sceneManager.highlight(node, Color3.FromHexString(targetState.color))
    } else if (!targetState.active) {
      // no-op?
    }
  }

  onAfterTransition(node, _originState, targetState = {}) {
    const { sceneManager } = rootStore
    if (!targetState.active) {
      sceneManager.highlightManager.clearHighlightHistory(node)
      sceneManager.unhighlight(node)
    } else {
      node.overlayAlpha = 0.7
    }
  }

  applyToNode(node, _originState = {}, targetState = {}, refState, t = 1) {
    ver
    if (refState.wasHighlighted && targetState.active) {
      // no-op
    } else {
      const fadeIn = !refState.wasHighlighted && targetState.active
      node.overlayAlpha = fadeIn ? t : 1 - t
    }
  }

  resetToOriginal(node, _originState = {}, _targetState = {}) {
    const { sceneManager } = rootStore
    sceneManager.unhighlight(node)
    node.renderOverlay = false
  }

  getRefState(node, _originState, _targetState) {
    return { wasHighlighted: node.renderOverlay }
  }
}

export default new HighlightTool()
