import LoginPageView from './LoginPageView'
import { Intent } from '@blueprintjs/core'
import { useHistory } from 'react-router-dom'
import toaster from '~/src/features/toaster/toaster'
import { useStore } from '~/src/app/store'

const initialValues = {
  email: '',
  password: '',
  remember: false,
}

const LoginPage = () => {
  const history = useHistory()
  const store = useStore()
  const handleLoginSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await store.auth.signIn(values.email, values.password)
      history.push(store.auth.protectedUrl || '/')
    } catch (e) {
      toaster.show({
        icon: 'error',
        intent: Intent.DANGER,
        message: e.message,
      })
      setSubmitting(false)
    }
  }
  return (
    <LoginPageView initialValues={initialValues} onSubmit={handleLoginSubmit} />
  )
}

export default LoginPage
