import { Alert, H4 } from '@blueprintjs/core'
import rootStore from '~/src/app/store'

const PivotWarningAlert = ({ onConfirm }) => {
  const { appState } = rootStore
  const onCancel = () => {
    appState.showPivotWarningModal = false
  }
  return (
    <Alert
      canOutsideClickCancel
      intent="danger"
      cancelButtonText="Cancel"
      confirmButtonText="Reset"
      icon="warning-sign"
      isOpen={true}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <H4>Warning</H4>
      <p>
        You cannot change the pivot point of a part that has already been
        rotated and/or scaled.
      </p>
      <p>
        If you wish to perform rotations using another pivot point, you will
        have to reset any rotations and/or changes in scale first.
      </p>
      <p>Are you sure you want to continue?</p>
    </Alert>
  )
}

export default PivotWarningAlert
