import { toJS, makeAutoObservable } from 'mobx'
import { v4 as uuid } from 'uuid'
import { get, assign, pick, cloneDeep, values, map, has } from 'lodash'

class Step {
  objects = {}
  // TODO: maybe change this 'null' for CameraPosition.INITIAL_CAMERA_POSITION or something
  camera = null
  thumbnail = null
  narration = null
  text = null

  // objects = {
  //   ['objectId']: {
  //     id: 'objectId',
  //     modelId: 'modelId',
  //     tools: {
  //       position: { /*...*/ },
  //       rotation: { /*...*/ },
  //       highlight: { /*...*/ },
  //     },
  //     nodes: {
  //       ['grandpa/parent/child']: {
  //         tools: {
  //           position: { x: 0, y: 1, z: 0 },
  //           rotation: { /*...*/ },
  //           highlight: { /*...*/ },
  //         }
  //       }
  //     }
  //   }
  // }

  constructor() {
    this.id = uuid()
    makeAutoObservable(this, {})
  }

  setThumbnail(url) {
    this.thumbnail = url
  }

  addObject(model) {
    const object = {
      id: uuid(),
      model: model,
      tools: {},
      nodes: {},
    }
    this.objects[object.id] = object
    return object
  }

  hasObject(id) {
    return has(this.objects, id)
  }

  getObject(id) {
    return get(this.objects, id, {})
  }

  removeObject(objectId) {
    delete this.objects[objectId]
  }

  getAllObjects() {
    return values(this.objects)
  }

  getAllObjectIds() {
    return map(this.getAllObjects(), 'id')
  }

  static clone(step) {
    const stepClone = new Step()
    return assign(stepClone, {
      objects: cloneDeep(step.objects),
      camera: cloneDeep(step.camera),
      thumbnail: step.thumbnail,
    })
  }

  toJSON() {
    return {
      id: toJS(this.id),
      objects: toJS(this.objects),
      camera: toJS(this.camera),
      thumbnail: toJS(this.thumbnail),
      narration: toJS(this.narration),
      text: toJS(this.text),
    }
  }

  static fromJSON(json) {
    const step = new Step()
    return assign(
      step,
      pick(json, ['id', 'objects', 'camera', 'thumbnail', 'narration', 'text']),
    )
  }
}

export default Step
