import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import highlightTool from '~/src/features/tools/highlight/highlightTool'

// TODO: get current active value from tool

const ColorPickerSample = ({ color, onClick, active }) => {
  const activeColor = highlightTool.getHighlightColor()
  const isActive = active !== undefined ? active : color === activeColor
  return (
    <div
      className={cx('highlight-color-sample', { active: isActive })}
      onClick={onClick || (() => highlightTool.setHighlightColor(color))}
    >
      <div className="sample" style={{ backgroundColor: color }} />
    </div>
  )
}

const HighlightColorPicker = () => {
  const activeColor = highlightTool.getHighlightColor()
  return (
    <div className="highlight-color-picker">
      <ColorPickerSample
        color="#FFFFFF"
        onClick={highlightTool.clearHighlight}
        active={!activeColor}
      />
      <ColorPickerSample color="#48AFF0" />
      <ColorPickerSample color="#D13913" />
      <ColorPickerSample color="#62D96B" />
      <ColorPickerSample color="#FFC940" />
    </div>
  )
}

export default observer(HighlightColorPicker)
