import { useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Icon, Label, AnchorButton, ProgressBar } from '@blueprintjs/core'
import { useFileUploader, modes } from '~/src/hooks/fileUploader'
import { useStore } from '~/src/app/store'
import CustomIcon from '~/src/app/components/Icon'

const UploadModelButton = ({ fileUploader }) => (
  <form>
    <input
      accept=".glb, .gltf"
      type="file"
      id="model-file-upload"
      hidden
      onChange={e => {
        const file = e.target.files[0]
        if (file) fileUploader.handleUpload(file)
      }}
    />
    <Label className="upload-button" htmlFor="model-file-upload">
      <AnchorButton
        icon={<CustomIcon icon="upload" />}
        minimal
        intent="primary"
      >
        Upload model
      </AnchorButton>
    </Label>
  </form>
)

const UploadProgress = ({ fileUploader }) => (
  <ProgressBar
    value={fileUploader.progress}
    intent="primary"
    className="upload-progress"
  />
)

const ConfirmationMessage = () => (
  <div className="confirmation-message">
    <Icon icon="tick-circle" intent="success" />
    <span>Model uploaded succesfully</span>
  </div>
)

const UploadModel = () => {
  const { modelGallery } = useStore()
  // file upload logic to USER-LEVEL models/ folder (not training-level!)
  const fileUploader = useFileUploader({
    folder: 'models/',
    onFinish: (fileName, fileUrl) => {
      // to remove the confirmation message
      timerId.current = setTimeout(() => fileUploader.handleReset(), 1500)
      modelGallery.addModelToGallery({ fileName, fileUrl })
    },
    onError: console.error,
  })
  const mode = fileUploader.mode
  // cancel timeout on unmount
  const timerId = useRef()
  useEffect(() => () => clearTimeout(timerId.current), [])
  return (
    <>
      {(mode === modes.READY && <ConfirmationMessage />) ||
        (mode === modes.PROGRESS && (
          <UploadProgress fileUploader={fileUploader} />
        )) || <UploadModelButton fileUploader={fileUploader} />}
    </>
  )
}

export default observer(UploadModel)
