import { flow } from 'mobx'
import { Vector3 } from '@babylonjs/core/Maths/math'
import Step from '~/src/features/training/step'
import { createPromise } from '~/src/utils/general'
import { pick, forEach, assign, difference, intersection } from 'lodash'

const CAMERA_TRANSITION_FACTOR = 250

class Transitions {
  previousActiveStep = Step.fromJSON({ id: -1 })
  activeSemaphore = null
  semaphoreQueue = []

  constructor(rootStore) {
    assign(
      this,
      pick(rootStore, [
        'camera',
        'training',
        'modelRepository',
        'sceneManager',
        'toolbar',
        'animator',
        'appState',
        'player',
      ]),
    )
  }

  reset() {
    this.previousActiveStep = Step.fromJSON({ id: -1 })
    this.semaphoreQueue = []
    this.activeSemaphore = null
    this.enable()
  }

  halt() {
    forEach(this.semaphoreQueue, semaphore => semaphore.resolve())
    if (this.activeSemaphore) this.activeSemaphore.resolve()
  }

  enable() {
    this.stopped = false
  }

  disable() {
    this.stopped = true
    this.halt()
  }

  requestSemaphore() {
    if (this.activeSemaphore) {
      const prevSemaphore = this.activeSemaphore
      this.semaphoreQueue.push(prevSemaphore)
    }
    this.activeSemaphore = createPromise()
    return this.activeSemaphore
  }

  async waitAllPendingSemaphores() {
    await Promise.all(this.semaphoreQueue)
    this.semaphoreQueue = []
    this.activeSemaphore = null
  }

  onSelectedStepChange = flow(function* (newSelectedStepId) {
    const { training, animator, camera, sceneManager, player } = this
    camera.stopTransition()
    animator.stopActiveTransition()
    const semaphore = this.requestSemaphore()
    console.log('* transitions waiting...')
    yield this.waitAllPendingSemaphores()
    console.log('* trasitions ready!')
    // kill it if stopped during the yield
    if (this.stopped) return
    const originStep = this.previousActiveStep
    const targetStep = training.findStep(newSelectedStepId)
    const isFirstStep = originStep.id === -1
    // if camera, first move the camera
    if (targetStep.camera && originStep.id !== targetStep.id) {
      if (isFirstStep || sceneManager.scene.meshes.length < 2) {
        // this is the first step -> teleport the camera
        // OR there is nothing on the screen
        animator.runBeforeTransition(() => {
          // for some reason I have to do this after the transition is done
          camera.setPose(targetStep.camera)
        })
      } else {
        const position = Vector3.FromArray(targetStep.camera.position)
        const target = Vector3.FromArray(targetStep.camera.target)
        const distance = Vector3.Distance(
          sceneManager.camera.position,
          position,
        )
        const targetDistance = Vector3.Distance(
          sceneManager.camera.target,
          target,
        )
        const duration = (distance + targetDistance) * CAMERA_TRANSITION_FACTOR
        yield camera.startTransition(position, target, Math.max(500, duration))
      }
    }
    // then, animate the scene
    animator.startTransition(originStep, targetStep)
    const currentObjects = originStep.getAllObjectIds()
    const targetObjects = targetStep.getAllObjectIds()
    // calculate object deltas
    const toRemove = difference(currentObjects, targetObjects)
    const toAdd = difference(targetObjects, currentObjects)
    const toTransition = intersection(currentObjects, targetObjects)
    // add/remove models
    forEach(toRemove, id => animator.dematerializeModel(id))
    forEach(toAdd, id => animator.materializeModel(id))
    forEach(toTransition, id => {
      animator.transitionStepObject(originStep, targetStep, id)
    })
    // save the selected step for transitioning out in the future
    this.previousActiveStep = targetStep
    // hide the toolbar if the selected part is now hidden
    this.toolbar.unselectNodeIfHidden()
    // open the semaphore
    animator.runAfterTransition(() => {
      semaphore.resolve()
      player.onTransitionFinished()
    })
  })
}

export default Transitions
