import { isEmpty } from 'lodash'

export const getInstructionsNode = model => {
  const topLevelNodes = model.getChildren()
  return topLevelNodes.find(n => n.name === 'instructions')
}

export const prepareBlenderModel = model => {
  const instructionsNode = getInstructionsNode(model)
  if (instructionsNode) {
    const stepNodes = instructionsNode.getChildren()
    for (const node of stepNodes) node.setEnabled(false)
  }
}

export const hasBlenderSteps = model => {
  return !!getInstructionsNode(model)
}

export const getBlenderSteps = model => {
  return getInstructionsNode(model).getChildren()
}

const isChildOf = (node, parentId) => {
  if (!node.parent) return false
  else if (node.parent.id === parentId) return true
  else return isChildOf(node.parent, parentId)
}

export const getAnimationsForNode = (scene, nodeId) => {
  return scene.animationGroups.filter(ag => {
    const { children } = ag
    return !isEmpty(children) && isChildOf(children[0].target, nodeId)
  })
}

export const getAnimationGroupDuration = animationGroup => {
  const fps = animationGroup.children[0].animation.framePerSecond
  const frameRange = animationGroup.to - animationGroup.from
  return (frameRange / fps) * 1000
}
