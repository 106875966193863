import { makeAutoObservable } from 'mobx'
import { Vector3, Matrix, Color3 } from '@babylonjs/core/Maths/math'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { nuke } from '~/src/utils/nodes'
import { forEach, assign } from 'lodash'

class LabelsStore {
  markers = new Map()
  labels = new Map()

  constructor({ sceneManager, modelRepository }) {
    makeAutoObservable(this, {})
    assign(this, { sceneManager, modelRepository })
  }

  reset() {
    this.markers.clear()
    this.labels.clear()
  }

  createMarker(node, point, normal) {
    const scale = this.modelRepository.getScaleForNode(node)
    const markerSeparation = -0.03 / scale
    const position = point.subtract(normal.scale(markerSeparation))
    const marker = MeshBuilder.CreateSphere('marker', {
      diameter: 0.01 / scale,
    })
    marker.parent = node
    marker.position = position

    marker.occlusionQueryAlgorithmType =
      AbstractMesh.OCCLUSION_ALGORITHM_TYPE_ACCURATE
    marker.occlusionType = AbstractMesh.OCCLUSION_TYPE_STRICT
    marker.occlusionRetryCount = 3
    marker.isOccluded = true
    marker.isPickable = false
    marker.material = this.getMarkerMaterial()
    marker.material.alpha = 0
    return marker
  }

  getMarkerMaterial() {
    if (!this.markerMaterial) {
      // material reused by all markers
      this.markerMaterial = new StandardMaterial('marker-material')
      this.markerMaterial.diffuseColor = Color3.White()
      this.markerMaterial.emissiveColor = Color3.White()
    }
    return this.markerMaterial
  }

  hideMarkers() {
    this.markers.forEach(marker => marker.setEnabled(false))
  }

  showMarkers() {
    this.markers.forEach(marker => marker.setEnabled(true))
  }

  hasLabel(node) {
    return this.labels.has(node)
  }

  addLabel(node, delta, normal, text) {
    if (!this.markers.has(node)) {
      const marker = this.createMarker(node, delta, normal)
      this.markers.set(node, marker)
    }
    this.labels.set(node, text)
  }

  setLabel(node, text) {
    this.labels.set(node, text)
  }

  removeLabel(node) {
    const marker = this.markers.get(node)
    if (marker) nuke(marker)
    this.labels.delete(node)
    this.markers.delete(node)
  }

  removeChildNodeLabels(node) {
    const nodes = node.getDescendants()
    forEach(nodes, node => {
      if (node.name === 'marker') this.removeLabel(node.parent)
    })
  }

  getProjectedLabels() {
    const labels = []
    const { camera, scene, engine } = this.sceneManager
    this.labels.forEach((text, node) => {
      if (!node.isEnabled()) return
      const marker = this.markers.get(node)
      const id = marker.uniqueId
      const visible = !marker.isOccluded
      const { x, y } = Vector3.Project(
        marker.getAbsolutePosition(),
        Matrix.IdentityReadOnly,
        scene.getTransformMatrix(),
        camera.viewport.toGlobal(
          engine.getRenderWidth(),
          engine.getRenderHeight(),
        ),
      )
      labels.push({ text, id, visible, x, y, node })
    })
    return labels
  }
}

export default LabelsStore
