import { H4 } from '@blueprintjs/core'
import CustomIcon from '~/src/app/components/Icon'

const RestrictionsMobileOverlay = () => {
  return (
    <div className="restrictions-mobile-overlay">
      <div className="panel">
        <CustomIcon icon="warning" />
        <div className="content">
          <H4>Your device does not meet the requirements</H4>
          <p>Our editor is available for devices at least 680 pixels wide.</p>
        </div>
      </div>
    </div>
  )
}

export default RestrictionsMobileOverlay
