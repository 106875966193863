import axios from 'axios'
import { currentAuthenticatedUserId, currentUserToken } from '../auth'
import { env } from '../env'

export async function refreshTrainingIndex(user, authorizationHeader) {
  const authorization = authorizationHeader || (await currentUserToken())
  const userId = user || (await currentAuthenticatedUserId())

  const response = await axios.post(
    `/u/${userId}/refresh-index`,
    {},
    {
      baseURL: env.managerAPI,
      headers: { Authorization: authorization },
    },
  )
  return response.data
}
