import { useState, useEffect } from 'react'
import { useStore } from '~/src/app/store'
import { coerce } from '~/src/utils/general'

export function useSelectedNodePosition() {
  const { toolbar, sceneManager } = useStore()
  // update position when changing selected part or when camera moves
  const [position, setPosition] = useState(toolbar.getSelectedNodePosition())
  const updatePosition = () => {
    if (toolbar.selectedNode) {
      setPosition(toolbar.getSelectedNodePosition())
    }
  }
  useEffect(updatePosition, [toolbar.selectedNode])
  useEffect(() => {
    const { onViewMatrixChangedObservable } = sceneManager.camera
    onViewMatrixChangedObservable.add(updatePosition)
    toolbar.updateFloatingPosition = updatePosition
    return () => {
      onViewMatrixChangedObservable.removeCallback(updatePosition)
      delete toolbar.updateFloatingPosition
    }
  }, [])

  return position
}

export function calculateToolbarPosition(selectedNodePosition) {
  // lots of magic constants in this function...
  const leftToolbarWidth = 225
  // const leftToolbarWidth = 0
  const toolbarHeight = 340
  const instructionBoxHeight = 80
  const width = window.innerWidth
  const height = window.innerHeight
  const offset =
    selectedNodePosition.x < (width + leftToolbarWidth) / 2 ? 300 : -300
  const x = coerce(
    selectedNodePosition.x + offset - leftToolbarWidth,
    5,
    width - 50 - leftToolbarWidth,
  )
  const y = coerce(
    selectedNodePosition.y - toolbarHeight / 2,
    5,
    height - toolbarHeight - instructionBoxHeight,
  )
  return { x, y }
}

export function useToolbarFloatingPosition() {
  const selectedNodePosition = useSelectedNodePosition()
  return calculateToolbarPosition(selectedNodePosition)
}

export function useToolbarIsHidden() {
  const { toolbar } = useStore()
  return !toolbar.selectedNode
}
