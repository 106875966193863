import { makeAutoObservable } from 'mobx'
import Auth from '~/src/features/backend/auth'

const CREDENTIALS_REFRESH_INTERVAL = 30 * 60000

class AuthStore {
  currentUser = null
  impersonatedUser = null
  protectedUrl = null

  constructor(rootStore) {
    makeAutoObservable(this, { protectedUrl: false })
    this.rootStore = rootStore
    window.logout = this.signOut
  }

  async checkImpersonation() {
    const impersonated = new URLSearchParams(window.location.search).get('as')
    const currentUser = await Auth.currentAuthenticatedUser()
    // @TODO: security gap, this should not be a frontend check :)
    if (Auth.isUserAdmin(currentUser) && impersonated) {
      this.impersonatedUser = impersonated
    }
  }

  async currentCredentials() {
    return Auth.currentCredentials()
  }

  async currentUserId() {
    return (await Auth.currentAuthenticatedUser()).username
  }

  async fetchCurrentAuthenticatedUser() {
    this.currentUser = await Auth.currentAuthenticatedUser()
    if (this.currentUser)
      setInterval(this.refreshCredentials, CREDENTIALS_REFRESH_INTERVAL)
  }

  async signIn(email, password) {
    this.currentUser = await Auth.signIn(email, password)
    if (this.currentUser)
      setInterval(this.refreshCredentials, CREDENTIALS_REFRESH_INTERVAL)
    console.log('[auth] signed in as:', this.currentUser)
  }

  async signOut() {
    await Auth.signOut()
    console.log('signed out')
    this.currentUser = null
  }

  async refreshCredentials() {
    await Auth.currentSession()
    this.rootStore.persistence.initialize(true)
  }
}

export default AuthStore
