import rootStore from '~/src/app/store'
import { Vector3, Matrix } from '@babylonjs/core/Maths/math'
import { assign } from 'lodash'

import { toJS } from 'mobx'

class LabelTool {
  key = 'label'

  attachToScene(node, state) {
    assign(this, { node, state })
  }

  dettachFromScene(_node, _state) {
    assign(this, { node: null, state: null })
  }

  // called form ui

  hasLabel() {
    return !!this.state.active
  }

  getLabel() {
    return this.state.text
  }

  setLabel(text) {
    const { labels } = rootStore
    this.state.text = text
    // update the scene
    labels.setLabel(this.node, text)
  }

  activateLabel() {
    const { labels, toolbar, undo, sceneManager } = rootStore
    undo.saveSnapshot()
    this.state.active = true
    // store the point where the user clicked to show the toolbar
    // (as delta to node center, to move it when the model moves)
    // this.state.delta = toolbar.pickPoint.subtract(node.absolutePosition)
    const m = new Matrix()
    this.node.getWorldMatrix().invertToRef(m)
    const localPosition = Vector3.TransformCoordinates(toolbar.pickPoint, m)
    this.state.delta = localPosition.asArray()
    this.state.normal = toolbar.pickNormal.asArray()
    // update the scene
    const camera = sceneManager.camera
    const localToScreenVector = Vector3.TransformCoordinates(
      camera.position,
      m,
    ).subtract(localPosition)
    const localToScreenNormal = Vector3.Normalize(localToScreenVector)
    labels.addLabel(
      this.node,
      localPosition,
      localToScreenNormal,
      this.state.text,
    )
  }

  removeLabel() {
    const { labels } = rootStore
    this.state.active = false
    // update the scene
    labels.removeLabel(this.node)
  }

  // called from transition

  getRefState(_node, _originState, _targetState) {
    // not very relevant for this tool
    return {}
  }

  resetToOriginal(node, _originState, _targetState) {
    // I think this function is not really doing anything
    const { labels } = rootStore
    labels.removeLabel(node)
  }

  onBeforeDematerialization(node, _originState, _targetState) {
    // we want to remove the labels *before* the dematerialization starts
    const { labels } = rootStore
    labels.removeLabel(node)
  }

  applyToNode(node, _originState, targetState = {}, _refState, t) {
    // TODO: fade labels in/out?
    const { labels } = rootStore
    if (targetState.active && t === 1) {
      if (!labels.hasLabel(node)) {
        const delta = Vector3.FromArray(targetState.delta)
        const normal = Vector3.FromArray(targetState.normal)
        labels.addLabel(node, delta, normal, targetState.text)
      } else {
        labels.setLabel(node, targetState.text)
      }
    } else if (!targetState.active && labels.hasLabel(node)) {
      labels.removeLabel(node)
    }
  }
}

export default new LabelTool()
