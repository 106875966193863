import { observer } from 'mobx-react-lite'
import StepInstructionsText from './StepInstructionsText'
import StepInstructionsNarration from './StepInstructionsNarration'
import { useSelectedStep } from '~/src/hooks/training'

const StepInstructions = () => {
  const selectedStep = useSelectedStep()
  if (!selectedStep) return null
  return (
    <div className="step-instructions">
      <StepInstructionsNarration step={selectedStep} />
      <StepInstructionsText step={selectedStep} />
    </div>
  )
}

export default observer(StepInstructions)
