import React, { useEffect, useState, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStore } from '~/src/app/store'
import { useFileUploader, modes } from '~/src/hooks/fileUploader'
import { AnchorButton, Button, Label, Spinner } from '@blueprintjs/core'
import MicRecorder from 'mic-recorder-to-mp3'
import CustomIcon from '~/src/app/components/Icon'
import Tooltip from '~/src/utils/components/Tooltip'

const AudioPlayback = observer(({ audioFile, onDelete }) => {
  const { persistence } = useStore()
  const audio = useRef()
  const intervalId = useRef()
  const [isPlaying, setPlaying] = useState(false)
  const onTick = () => {
    if (audio.current && audio.current.paused) {
      setPlaying(false)
      clearInterval(intervalId.current)
    }
  }
  const handleStop = () => {
    setPlaying(false)
    audio.current.onEnded = undefined
    audio.current.pause()
  }
  const handlePlay = () => {
    setPlaying(true)
    audio.current.currentTime = 0
    audio.current.play()
    intervalId.current = setInterval(onTick, 100)
  }
  // stop on dismount
  useEffect(
    () => () => {
      audio.current && audio.current.stop()
      clearInterval(intervalId.current)
    },
    [],
  )
  return (
    <div className="playback-actions">
      <audio src={persistence.getContentUrl(audioFile)} ref={audio} />
      {isPlaying && (
        <Tooltip content="Stop" placement="top">
          <Button
            outlined
            icon={<CustomIcon icon="stop" />}
            onClick={handleStop}
          />
        </Tooltip>
      )}
      {!isPlaying && (
        <Tooltip content="Play" placement="top">
          <Button
            outlined
            icon={<CustomIcon icon="play" />}
            onClick={handlePlay}
          />
        </Tooltip>
      )}
      <Tooltip content="Delete" placement="top">
        <Button
          outlined
          icon={<CustomIcon icon="trash" />}
          onClick={onDelete}
        />
      </Tooltip>
    </div>
  )
})

const UploadAudioFile = ({ fileUploader }) => {
  return (
    <div className="upload-actions">
      <form>
        <input
          accept=".mp3, .m4a, .wav, .ogg"
          type="file"
          id="audio-file-upload"
          hidden
          onChange={e => {
            const file = e.target.files[0]
            if (file) fileUploader.handleUpload(file)
          }}
        />
        <Tooltip content="Upload audio file" placement="top">
          <Label htmlFor="audio-file-upload">
            <AnchorButton
              outlined
              disabled={false}
              icon={<CustomIcon icon="upload" />}
            />
          </Label>
        </Tooltip>
      </form>
    </div>
  )
}

const RecordMicrophone = ({ fileUploader }) => {
  const micRecorder = useRef()
  const [recording, setRecording] = useState(false)
  const [recordingTiemstamp, setRecordingTimestamp] = useState(Date.now())
  const [timerId, setTimerId] = useState()

  const MAX_RECORDING_DURATION = 30 * 1000

  const onTick = () => {
    console.log('on Tick')
    if (recording && Date.now() - recordingTiemstamp > MAX_RECORDING_DURATION) {
      onStopRecording()
    }
    setTimerId(setTimeout(onTick, 1000))
  }

  const onStartRecording = async () => {
    await micRecorder.current.start()
    setRecording(true)
    setRecordingTimestamp(Date.now())
    setTimerId(setTimeout(onTick, 1000))
  }

  const onStopRecording = async () => {
    const [buffer, blob] = await micRecorder.current.stop().getMp3()
    setRecording(false)
    clearTimeout(timerId)
    const fileName = `${uuid()}.mp3`
    const file = new File(buffer, fileName, {
      type: blob.type,
      lastModified: Date.now(),
    })
    fileUploader.handleUpload(file)
    // const key = await persistence.persistFile(fileName, file)
  }

  useEffect(() => {
    micRecorder.current = new MicRecorder({ bitRate: 128 })
  }, [])

  const timer = (Date.now() - recordingTiemstamp) / MAX_RECORDING_DURATION

  return (
    <div className="record-microphone">
      <Tooltip content="Record audio" placement="top">
        <Label>
          {recording && (
            <AnchorButton
              className="spinner-box"
              outlined
              onClick={onStopRecording}
            >
              <span className="recording-hint"></span>
              <Spinner intent="danger" size={30} value={timer} />
            </AnchorButton>
          )}
          {!recording && (
            <AnchorButton
              onClick={onStartRecording}
              outlined
              disabled={false}
              icon={<CustomIcon icon="microphone" />}
            />
          )}
        </Label>
      </Tooltip>
    </div>
  )
}

const StepInstructionsNarration = ({ step }) => {
  const { training, persistence } = useStore()
  const stepNarration = step.narration
  // handlers
  const handleAudioUpload = action(
    (file, key) => (step.narration = { file, key }),
  )
  const handleDelete = action(() => (step.narration = null))
  // uploading
  const fileUploader = useFileUploader({
    fileName: stepNarration,
    folder: persistence.trainingPath(training.id, 'uploads/'),
    onFinish: handleAudioUpload,
    onDelete: handleDelete,
  })
  return (
    <div className="step-instructions-narration">
      {[modes.EMPTY, modes.UPLOAD].includes(fileUploader.mode) && (
        <RecordMicrophone fileUploader={fileUploader} />
      )}
      {[modes.EMPTY, modes.UPLOAD].includes(fileUploader.mode) && (
        <UploadAudioFile fileUploader={fileUploader} />
      )}
      {fileUploader.mode === modes.PROGRESS && (
        <Spinner size={30} intent="primary" className="spinner-wrapper" />
      )}
      {fileUploader.mode === modes.READY && stepNarration && (
        <AudioPlayback
          audioFile={stepNarration.key}
          onDelete={fileUploader.handleDelete}
        />
      )}
    </div>
  )
}

export default observer(StepInstructionsNarration)
