import {
  EasingFunction,
  SineEase,
  QuadraticEase,
  CubicEase,
  CircleEase,
  BackEase,
  ElasticEase,
} from '@babylonjs/core/Animations/easing'

const easingFunctions = {
  sine: new SineEase(),
  quadratic: new QuadraticEase(),
  cubic: new CubicEase(),
  circle: new CircleEase(),
  back: new BackEase(1),
  elastic: new ElasticEase(2, 1),
}

const ease = (t, fn, easeIn, easeOut) => {
  const easingFn = easingFunctions[fn]
  switch (true) {
    case fn === 'linear':
    case !easeIn && !easeOut:
      return t
    case easeIn && easeOut:
      easingFn.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT)
      break
    case easeIn:
      easingFn.setEasingMode(EasingFunction.EASINGMODE_EASEIN)
      break
    case easeOut:
      easingFn.setEasingMode(EasingFunction.EASINGMODE_EASEOUT)
      break
  }
  return easingFn.ease(t)
}

export default ease
