import { Button } from '@blueprintjs/core'
import logo from '~/assets/images/aucta-next-logo.png'

const Header = ({ hasLogout }) => {
  return (
    <header className="main-header">
      <div className="wrapper">
        <img src={logo} alt="aucta-next" className="logo" />
        {hasLogout ? <Button minimal text="Logout" icon="log-out" /> : null}
      </div>
    </header>
  )
}

export default Header
