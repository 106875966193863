import { useEffect, useRef } from 'react'
import { useStore } from '~/src/app/store'

export const useSceneInitializer = () => {
  const { appState, sceneManager, modelRepository, toolbar, animator } =
    useStore()
  const canvasRef = useRef(null)
  useEffect(() => {
    if (!canvasRef.current) return
    appState.initialize()
    sceneManager.initialize(canvasRef.current)
    toolbar.attachToScene()
    animator.startThread()
    return () => {
      modelRepository.disposeAllModels()
      toolbar.dispose()
      animator.stopThread()
      sceneManager.destroy(canvasRef.current)
    }
  }, [canvasRef.current])
  return canvasRef
}
