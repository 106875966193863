import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStepsSequence } from '~/src/hooks/training'
import AddStepButton from '~/src/features/stepList/AddStepButton'
import StepThumbnail from './StepThumbnail'
import cx from 'classnames'

const StepList = () => {
  const stepSequence = useStepsSequence()
  const container = useRef(null)
  const [scroll, setScroll] = useState(false)

  const maybeAddScrollClass = () => {
    // this effect adds a class when the container has a scroll bar
    // to fix an issue in Firefox (scrollbar overlaps thumbnails)
    if (!container.current) return
    const { height } = container.current.getBoundingClientRect()
    const windowHeight = window.innerHeight
    // 50px is the height of the "Add Step" button
    if (height >= windowHeight - 50) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  // recalculate scroll class on window resize
  useEffect(() => {
    window.addEventListener('resize', maybeAddScrollClass)
    return () => window.removeEventListener('resize', maybeAddScrollClass)
  }, [])

  // recalculate scroll class when step list changes
  useEffect(() => maybeAddScrollClass())

  return (
    <div className="step-list-sidebar">
      <div
        className={cx('step-list', { 'has-scroll': scroll })}
        ref={container}
      >
        {stepSequence.map(stepId => (
          <StepThumbnail key={stepId} stepId={stepId} />
        ))}
      </div>
      <AddStepButton />
    </div>
  )
}

export default observer(StepList)
