import { Tooltip2 } from '@blueprintjs/popover2'

const Tooltip = ({ content, children, placement = 'right', isOpen }) => {
  return (
    <Tooltip2
      content={content}
      placement={placement}
      usePortal={false}
      hoverOpenDelay={800}
      isOpen={isOpen}
    >
      {children}
    </Tooltip2>
  )
}

export default Tooltip
