import { observer } from 'mobx-react-lite'
import { Button } from '@blueprintjs/core'
import { useAddStep } from '~/src/hooks/training'
import CustomIcon from '~/src/app/components/Icon'

const AddStepButton = () => {
  const addStep = useAddStep()
  return (
    <div className="add-step-button">
      <Button
        fill
        outlined
        intent="primary"
        icon={<CustomIcon icon="add" />}
        onClick={addStep}
      >
        Add Step
      </Button>
    </div>
  )
}

export default observer(AddStepButton)
