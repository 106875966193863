import React from 'react'
import { Alert, H4 } from '@blueprintjs/core'
import CustomIcon from '~/src/app/components/Icon'

const DeleteStepAlert = ({
  stepToDelete,
  confirmDeleteStep,
  cancelDeleteStep,
}) => {
  return (
    <Alert
      canOutsideClickCancel
      intent="danger"
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      icon={<CustomIcon icon="trash" />}
      isOpen={stepToDelete}
      onCancel={cancelDeleteStep}
      onConfirm={confirmDeleteStep}
    >
      <H4>Delete Step</H4>
      <p>Are you sure you want to delete this step?</p>
    </Alert>
  )
}

export default DeleteStepAlert
