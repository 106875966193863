import Auth from '@aws-amplify/auth'
import { managerAuthConfig } from '../config/aws-exports'

Auth.configure(managerAuthConfig)

export function respondToAuthChallenge(cognitoUser, newPassword) {
  return Auth.completeNewPassword(cognitoUser, newPassword, null)
}

export function signUp(email, password, name, surname, company) {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      name,
      family_name: surname,
      'custom:company': company,
    },
  })
}

export function signIn(email, password) {
  return Auth.signIn(email, password)
}

export function signOut() {
  return Auth.signOut()
}

export function forgotPassword(email) {
  return Auth.forgotPassword(email)
}

export function recoveryChangePassword(email, code, newPassword) {
  return Auth.forgotPasswordSubmit(email, code, newPassword)
}

export function currentSession() {
  return Auth.currentSession()
}

export function currentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser()
}

export async function currentAuthenticatedUserId() {
  return (await Auth.currentAuthenticatedUser()).username
}

export async function currentCredentials() {
  return Auth.currentCredentials()
}

export async function currentUserToken() {
  const user = await currentAuthenticatedUser()
  return user.signInUserSession.idToken.jwtToken
}

export async function currentUserId() {
  const user = await currentAuthenticatedUser()
  return user.username
}

export async function changePassword(oldPassword, newPassword) {
  const user = await Auth.currentAuthenticatedUser()
  return Auth.changePassword(user, oldPassword, newPassword)
}

export function isUserAdmin(user) {
  const adminGroupParam =
    user?.signInUserSession?.accessToken?.payload['cognito:groups']
  if (!adminGroupParam) return false
  return adminGroupParam.includes('Manager--Admin')
}

export default {
  signUp,
  signIn,
  signOut,
  isUserAdmin,
  forgotPassword,
  recoveryChangePassword,
  currentSession,
  currentCredentials,
  currentAuthenticatedUser,
  currentAuthenticatedUserId,
  changePassword,
}

export { CognitoUserSession } from 'amazon-cognito-identity-js'
