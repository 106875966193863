import { observable } from 'mobx'
import { observer } from 'mobx-react-lite'
import ToolbarUI from './ToolbarUI'
import { TOOL_MODE, ATTRIBUTE_PROPAGATION_MODE } from './store'
import AncestorSelectorMenu from './AncestorSelectorMenu'
import { useStore } from '~/src/app/store'
import { pull } from 'lodash'

/*
 * NOTE:
 * This is a modal element, with two possible behaviours depending on active mode.
 * That is why this component defines two different controllers, and a way to switch.
 */

// MODE 1: Tool Activation

const useToolbarController = () => {
  const { toolbar } = useStore()
  return observable({
    // atribute propagation
    isAttributeMenuOpen: false,
    onAttributePropagationButtonClick: () => {
      toolbar.deactivateCurrentTool()
      toolbar.toolbarMode = ATTRIBUTE_PROPAGATION_MODE
    },
    propagateAttributes(_operation) {
      return false /* no-op */
    },
    // tool management
    activateTool(tool, ...params) {
      toolbar.toolbarButtonClicked = tool
      toolbar.activateTool(tool, ...params)
    },
    isToolActive(tool) {
      return toolbar.selectedTool === tool
    },
    isToolMenuOpen(tool) {
      return this.isToolActive(tool)
    },
  })
}

// MODE 2: Attribute Propagation

const useAttributePropagationController = () => {
  const { toolbar } = useStore()
  const controller = observable({
    // attribute propagation
    isAttributeMenuOpen: true,
    onAttributePropagationButtonClick: () => {
      toolbar.resetToolbarState()
      toolbar.toolbarMode = TOOL_MODE
    },
    // atrribute selection
    toggleToolSelected(tool) {
      if (this.isToolActive(tool)) {
        pull(toolbar.selectedAttributes, tool)
      } else {
        toolbar.selectedAttributes.push(tool)
      }
    },
    activateTool(tool) {
      this.toggleToolSelected(tool)
    },
    openToolMenu(tool) {
      // in this mode menus are always closed
      this.toggleToolSelected(tool)
    },
    isToolActive(tool) {
      return toolbar.selectedAttributes.includes(tool)
    },
    isToolMenuOpen(_tool) {
      return false
    },
  })
  return controller
}

const Toolbar = () => {
  const { toolbar } = useStore()
  // mode controllers
  const toolController = useToolbarController()
  const attributeController = useAttributePropagationController()
  // select controller based on active mode
  const controller =
    toolbar.toolbarMode === TOOL_MODE ? toolController : attributeController
  return (
    <>
      <AncestorSelectorMenu />
      <ToolbarUI controller={controller} />
    </>
  )
}

export default observer(Toolbar)
