const region = 'eu-central-1'
const bucket = 'manager-user-content'
const cloudFrontDistribution = 'E3GHQO3FRR5YZ3'
const tempLinkDuration = 24 * 3600

export default {
  s3: {
    apiVersion: '2006-03-01',
    region,
    params: { Bucket: bucket },
  },
  region,
  bucket,
  cloudFrontDistribution,
  tempLinkDuration,
  contentServer: 'https://content.aucta.io',
  // contentServer: 'https://manager-user-content.s3.eu-central-1.amazonaws.com',
}
