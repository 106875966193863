export class TrainingDoesNotExist extends Error {
  constructor(trainingId, ...args) {
    super(...args)
    this.message = `Training ${
      trainingId ? trainingId + ' ' : ''
    }does not exist`
  }
}

export class OldVersionTrainingError extends Error {
  constructor(token, ...args) {
    super(...args)
    this.message = `Training ${
      token ? token + ' ' : ''
    }is not supported by this player version.`
  }
}
