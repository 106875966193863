{
  "Miscellaneous": {
    "items": [
      {
        "name": "arrow, glow",
        "id": "fb9191be-7d0a-4e3b-9e36-6660696c3473",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/arrow, glow.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/arrow, glow.glb"
      },
      {
        "name": "number 0",
        "id": "57368436-39a2-4c6e-a25a-21e3b46dc2af",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 0.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 0.glb"
      },
      {
        "name": "number 1",
        "id": "e36f25a4-a0a0-4384-a318-9d28fd0080d9",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 1.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 1.glb"
      },
      {
        "name": "number 2",
        "id": "e6e26b27-98e4-4eee-83bd-65d010271a71",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 2.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 2.glb"
      },
      {
        "name": "number 3",
        "id": "1e006ea6-34c1-469f-aa2d-eae40e9f2e72",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 3.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 3.glb"
      },
      {
        "name": "number 4",
        "id": "414cfce7-0863-4c4d-9795-60d49261bc30",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 4.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 4.glb"
      },
      {
        "name": "number 5",
        "id": "90d50a89-0126-4526-99e8-3e9cb7cb17b2",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 5.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 5.glb"
      },
      {
        "name": "number 6",
        "id": "f83c9cc5-174b-4c12-bfe3-0faad3ef1aa1",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 6.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 6.glb"
      },
      {
        "name": "number 7",
        "id": "06bfc4b1-c800-4e6a-8cfb-cfabcf8f7645",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 7.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 7.glb"
      },
      {
        "name": "number 8",
        "id": "7c8d8e4a-4332-4e04-b3ee-421163f69266",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 8.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 8.glb"
      },
      {
        "name": "number 9",
        "id": "37501be4-0c70-4d08-af58-b77f1df3c290",
        "thumbnail": "https://content.aucta.io/media/common-models/Miscellaneous/number 9.png",
        "url": "https://content.aucta.io/media/common-models/Miscellaneous/number 9.glb"
      }
    ],
    "name": "Miscellaneous"
  },
  "Office": {
    "items": [
      {
        "name": "mid-century table",
        "id": "24ae82b1-6933-4a08-b3ec-3e5750cdcdc6",
        "thumbnail": "https://content.aucta.io/media/common-models/Office/mid-century table.png",
        "url": "https://content.aucta.io/media/common-models/Office/mid-century table.glb"
      },
      {
        "name": "office table and chairs",
        "id": "c7d305c2-2e2a-4a9a-9da9-03b0001b6fb9",
        "thumbnail": "https://content.aucta.io/media/common-models/Office/office table and chairs.png",
        "url": "https://content.aucta.io/media/common-models/Office/office table and chairs.glb"
      },
      {
        "name": "steel office chair, no texture",
        "id": "7a1c3d6c-8636-483d-9fd8-fe2cef7228fd",
        "thumbnail": "https://content.aucta.io/media/common-models/Office/steel office chair, no texture.png",
        "url": "https://content.aucta.io/media/common-models/Office/steel office chair, no texture.glb"
      },
      {
        "name": "steel office chair, textured",
        "id": "07775b14-dd92-48a0-88df-74a17dd6c155",
        "thumbnail": "https://content.aucta.io/media/common-models/Office/steel office chair, textured.png",
        "url": "https://content.aucta.io/media/common-models/Office/steel office chair, textured.glb"
      },
      {
        "name": "trash can, office",
        "id": "30860b4d-9f39-45c7-9717-50a3f009fc82",
        "thumbnail": "https://content.aucta.io/media/common-models/Office/trash can, office.png",
        "url": "https://content.aucta.io/media/common-models/Office/trash can, office.glb"
      }
    ],
    "name": "Office"
  },
  "Safety": {
    "items": [
      {
        "name": "cleaning gloves",
        "id": "cd79837b-2716-460a-81cd-ff792d645c01",
        "thumbnail": "https://content.aucta.io/media/common-models/Safety/cleaning gloves.png",
        "url": "https://content.aucta.io/media/common-models/Safety/cleaning gloves.glb"
      },
      {
        "name": "hand sanitizer",
        "id": "7eb43050-f832-42ee-8bc6-9cf08d9c688b",
        "thumbnail": "https://content.aucta.io/media/common-models/Safety/hand sanitizer.png",
        "url": "https://content.aucta.io/media/common-models/Safety/hand sanitizer.glb"
      },
      {
        "name": "male head",
        "id": "a9aad884-48a5-4d9b-91ed-12b082afadbb",
        "thumbnail": "https://content.aucta.io/media/common-models/Safety/male head.png",
        "url": "https://content.aucta.io/media/common-models/Safety/male head.glb"
      },
      {
        "name": "mask, N95",
        "id": "afd6c788-e302-4ee3-a875-c577ff95bff3",
        "thumbnail": "https://content.aucta.io/media/common-models/Safety/mask, N95.png",
        "url": "https://content.aucta.io/media/common-models/Safety/mask, N95.glb"
      },
      {
        "name": "plastic bottle",
        "id": "34ce63b6-3d72-406b-85ba-e401c75f9c78",
        "thumbnail": "https://content.aucta.io/media/common-models/Safety/plastic bottle.png",
        "url": "https://content.aucta.io/media/common-models/Safety/plastic bottle.glb"
      },
      {
        "name": "soap bottle",
        "id": "b53c08eb-f234-48a7-9f2d-b3a2bf3968dd",
        "thumbnail": "https://content.aucta.io/media/common-models/Safety/soap bottle.png",
        "url": "https://content.aucta.io/media/common-models/Safety/soap bottle.glb"
      }
    ],
    "name": "Safety"
  },
  "Tools": {
    "items": [
      {
        "name": "adjustable wrench",
        "id": "bb499b62-3a5f-4a75-9420-944ac548aad1",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/adjustable wrench.png",
        "url": "https://content.aucta.io/media/common-models/Tools/adjustable wrench.glb"
      },
      {
        "name": "claw hammer",
        "id": "fc032332-28fc-4d6e-8545-dcd43eb4f27a",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/claw hammer.png",
        "url": "https://content.aucta.io/media/common-models/Tools/claw hammer.glb"
      },
      {
        "name": "crowbar",
        "id": "8035988f-d3c1-4bb3-867b-c855210d0e69",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/crowbar.png",
        "url": "https://content.aucta.io/media/common-models/Tools/crowbar.glb"
      },
      {
        "name": "drill",
        "id": "1ab3951e-02f8-47d9-97cb-fd073c31d012",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/drill.png",
        "url": "https://content.aucta.io/media/common-models/Tools/drill.glb"
      },
      {
        "name": "funnel",
        "id": "bef27c15-92e3-4981-b1f8-4f5cc90e64b4",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/funnel.png",
        "url": "https://content.aucta.io/media/common-models/Tools/funnel.glb"
      },
      {
        "name": "hammer, red",
        "id": "bd8aa03a-499a-43bb-a4e2-34e8673cae59",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/hammer, red.png",
        "url": "https://content.aucta.io/media/common-models/Tools/hammer, red.glb"
      },
      {
        "name": "hammer, yellow",
        "id": "39765a02-ef25-45a6-a93f-411c7e3fa084",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/hammer, yellow.png",
        "url": "https://content.aucta.io/media/common-models/Tools/hammer, yellow.glb"
      },
      {
        "name": "hex wrench",
        "id": "0ffc3708-b7b5-473d-9e85-1642688a0c64",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/hex wrench.png",
        "url": "https://content.aucta.io/media/common-models/Tools/hex wrench.glb"
      },
      {
        "name": "jerrycan",
        "id": "15082b13-c9d4-4531-898d-6844b821a837",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/jerrycan.png",
        "url": "https://content.aucta.io/media/common-models/Tools/jerrycan.glb"
      },
      {
        "name": "pliers",
        "id": "172c9937-c013-46ce-afd5-187b399430e7",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/pliers.png",
        "url": "https://content.aucta.io/media/common-models/Tools/pliers.glb"
      },
      {
        "name": "screwdriver, slotted",
        "id": "824eda43-a64b-4553-8c39-7d7875f41dc1",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/screwdriver, slotted.png",
        "url": "https://content.aucta.io/media/common-models/Tools/screwdriver, slotted.glb"
      },
      {
        "name": "torque wrench",
        "id": "42e4a75e-77fe-42f6-97b9-51d51ed6dbfb",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/torque wrench.png",
        "url": "https://content.aucta.io/media/common-models/Tools/torque wrench.glb"
      },
      {
        "name": "wheel wrench",
        "id": "9533dc63-20d3-403e-a98f-cbfa99cdb8d0",
        "thumbnail": "https://content.aucta.io/media/common-models/Tools/wheel wrench.png",
        "url": "https://content.aucta.io/media/common-models/Tools/wheel wrench.glb"
      }
    ],
    "name": "Tools"
  },
  "Warehouse": {
    "items": [
      {
        "name": "computer console",
        "id": "3f868e74-f679-475a-abf9-1e0e56b0faf1",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/computer console.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/computer console.glb"
      },
      {
        "name": "hand truck (dolly)",
        "id": "d310cb3d-b250-4223-9f2f-235b32c8a374",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/hand truck (dolly).png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/hand truck (dolly).glb"
      },
      {
        "name": "metallic trashcan, no texture",
        "id": "32cd8a10-c5f0-4110-9d3b-ff5c48c84d84",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/metallic trashcan, no texture.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/metallic trashcan, no texture.glb"
      },
      {
        "name": "metallic trashcan, textured",
        "id": "a16b05ee-abda-43de-bbda-2e5f22cdb732",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/metallic trashcan, textured.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/metallic trashcan, textured.glb"
      },
      {
        "name": "pallet, high-poly",
        "id": "bc958960-bbd8-45c5-9777-fb9fb3644004",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/pallet, high-poly.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/pallet, high-poly.glb"
      },
      {
        "name": "pallet, low-poly",
        "id": "d6f62706-b59d-4ad2-b34a-2d355a148368",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/pallet, low-poly.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/pallet, low-poly.glb"
      },
      {
        "name": "plastic pallet, no texture",
        "id": "9ab800c6-befa-4178-adc8-4e9f6e001716",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/plastic pallet, no texture.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/plastic pallet, no texture.glb"
      },
      {
        "name": "plastic pallet, textured",
        "id": "527c4a0d-4a41-450a-a251-9269cf57650b",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/plastic pallet, textured.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/plastic pallet, textured.glb"
      },
      {
        "name": "robot arm",
        "id": "e3267afe-e1e4-469d-8543-f3707660e9a7",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/robot arm.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/robot arm.glb"
      },
      {
        "name": "safety cone, no texture",
        "id": "072ab319-a674-4b81-bf62-0336c0f88e6b",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/safety cone, no texture.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/safety cone, no texture.glb"
      },
      {
        "name": "safety cone",
        "id": "100c5d2c-22e2-45ac-9279-14bd4a428eb2",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/safety cone.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/safety cone.glb"
      },
      {
        "name": "warehouse shelving, blue and orange",
        "id": "bba4d8c3-717c-4d27-adf4-db408041fde0",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/warehouse shelving, blue and orange.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/warehouse shelving, blue and orange.glb"
      },
      {
        "name": "warehouse shelving, mutliple",
        "id": "ab8778e3-e743-4ed7-a61b-1367cb6b3571",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/warehouse shelving, mutliple.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/warehouse shelving, mutliple.glb"
      },
      {
        "name": "warehouse",
        "id": "b4757b37-0aac-4a3a-834d-daf7a41550da",
        "thumbnail": "https://content.aucta.io/media/common-models/Warehouse/warehouse.png",
        "url": "https://content.aucta.io/media/common-models/Warehouse/warehouse.glb"
      }
    ],
    "name": "Warehouse"
  }
}
