import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { TextArea } from '@blueprintjs/core'

const StepInstructionsText = ({ step }) => {
  const text = step.text || ''
  const handleChange = action(e => (step.text = e.target.value))
  return (
    <div className="step-instructions-text">
      <TextArea
        placeholder="Click here to add additional instructions to this step..."
        value={text}
        onChange={handleChange}
      />
    </div>
  )
}

export default observer(StepInstructionsText)
