import rootStore from '~/src/app/store'
import { Vector3 } from '@babylonjs/core/Maths/math'
import { TransformNode } from '@babylonjs/core/Meshes/transformNode'
import {
  getRotation,
  getTransformWrapper,
  getPivotWrapper,
} from '~/src/utils/nodes'
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager'
import { disposeGizmoManager, stylePositionGizmo } from '../mixins'
import { assign, isEmpty } from 'lodash'
// debug
import { toJS } from 'mobx'
import { nuke } from '../../../utils/nodes'

class PositionTool {
  key = 'position'
  marker = null
  markerStartPosition = null
  startPosition = null
  state = null
  node = null

  attachToScene(node, state) {
    const transformWrapper = getTransformWrapper(node)
    assign(this, { state, node })
    this.setupMarker(node, transformWrapper)
    this.setupGizmos(transformWrapper, state)
    rootStore.appState.hideLabels()
    console.log('>> attach to scene: PositionTool', node, toJS(state))
  }

  dettachFromScene(node, _state) {
    this.gizmoManager.attachToMesh(null)
    disposeGizmoManager(this.gizmoManager)
    this.gizmoManager = null
    getTransformWrapper(node).onAfterWorldMatrixUpdateObservable.removeCallback(
      this.updateToolbarPosition,
    )
    // marker
    this.marker.onAfterWorldMatrixUpdateObservable.removeCallback(
      this.updatePartPosition,
    )
    nuke(this.marker)
    // state
    assign(this, { node: null, state: null, marker: null })
    rootStore.appState.showLabels()
  }

  // gizmos

  setupMarker(node, transformWrapper) {
    const pivotWrapper = getPivotWrapper(node)
    this.marker = new TransformNode('position-marker')
    this.marker.parent = transformWrapper.parent
    this.marker.rotation = getRotation(pivotWrapper)
    this.marker.position = transformWrapper.position.add(pivotWrapper.position)
    this.marker.onAfterWorldMatrixUpdateObservable.add(this.updatePartPosition)
    window.marker = this.marker
  }

  setupGizmos(wrapper, state) {
    const {
      undo,
      sceneManager: { scene },
    } = rootStore
    this.gizmoManager = new GizmoManager(scene, 2)
    this.gizmoManager.usePointerToAttachGizmos = false
    this.gizmoManager.positionGizmoEnabled = true
    this.gizmoManager.attachToMesh(this.marker)
    window.gm = this.gizmoManager
    stylePositionGizmo(this.gizmoManager)
    // store original node position (if not already saved)
    const { positionGizmo } = this.gizmoManager.gizmos
    positionGizmo.onDragStartObservable.add(() => {
      undo.saveSnapshot()
      this.startPosition = wrapper.position.clone()
      this.markerStartPosition = this.marker.position.clone()
      this.maybeStoreReferencePosition(state, this.startPosition)
    })
    // calculate the total movement delta (against the stored original reference)
    positionGizmo.onDragEndObservable.add(() => {
      const endPosition = wrapper.position.clone()
      this.storeTotalDelta(state, endPosition)
      this.startPosition = this.markerStartPosition = null
      rootStore.appState.updateStepThumbnail()
    })
    // update the floating toolbar position when moving the part
    wrapper.onAfterWorldMatrixUpdateObservable.add(this.updateToolbarPosition)
  }

  updateToolbarPosition = () => {
    const { toolbar } = rootStore
    toolbar.updateFloatingPosition()
  }

  updatePartPosition = () => {
    if (!this.markerStartPosition || !this.startPosition) return
    const wrapper = getTransformWrapper(this.node)
    const delta = this.marker.position.subtract(this.markerStartPosition)
    wrapper.position = this.startPosition.add(delta)
  }

  // data

  maybeStoreReferencePosition(state, startPosition) {
    // never overwrite originalPosition if present!
    if (!state.originalPosition) {
      state.originalPosition = startPosition.asArray()
    }
  }

  storeTotalDelta(state, endPosition) {
    state.position = endPosition.asArray()
    state.delta = endPosition
      .subtract(Vector3.FromArray(state.originalPosition))
      .asArray()
  }

  // application

  applyToNode(node, originState = {}, targetState = {}, refState, t = 1) {
    const wrapper = getTransformWrapper(node)
    if (isEmpty(originState) && isEmpty(targetState)) return
    // if no target position, then restore the original position
    const { startPosition } = refState
    const targetPosition = Vector3.FromArray(
      targetState.position || originState.originalPosition,
    )
    const position = Vector3.Lerp(startPosition, targetPosition, t)
    wrapper.position.copyFrom(position)
  }

  resetToOriginal(node, originState = {}, targetState = {}) {
    const wrapper = getTransformWrapper(node)
    if (isEmpty(originState) && isEmpty(targetState)) return
    const originalPosition = Vector3.FromArray(
      targetState.originalPosition || originState.originalPosition,
    )
    wrapper.position.copyFrom(originalPosition)
  }

  getRefState(node, _originState, _targetState) {
    const wrapper = getTransformWrapper(node)
    return { startPosition: wrapper.position.clone() }
  }
}

export default new PositionTool()
