import { makeAutoObservable } from 'mobx'
import { v4 as uuid } from 'uuid'
import commonGalleryModels from '~/data/common-models.json'
import config from './config'

class ModelGallery {
  userModels = {}
  commonModels = commonGalleryModels
  loading = false

  constructor({ persistence }) {
    makeAutoObservable(this, {})
    this.persistence = persistence
  }

  async fetchUserModels() {
    this.loading = true
    const data = await this.persistence.retrieveJson(config.userModelIndex)
    this.readJSON(data)
    this.loading = false
  }

  async persistUserModels() {
    const data = this.toJSON()
    await this.persistence.persistJson(config.userModelIndex, data)
  }

  removeModelFromGallery = async model => {
    delete this.userModels[model.id]
    await this.persistUserModels()
  }

  async addModelToGallery({ fileName, fileUrl }) {
    const id = uuid()
    const model = {
      id: id,
      name: fileName,
      url: fileUrl,
      thumbnail: `${config.contentServer}/media/default-model-thumbnail.png`,
    }
    // TODO: capture a screenshot?
    this.userModels[id] = model
    await this.persistUserModels()
  }

  toJSON() {
    return this.userModels
  }

  readJSON(json) {
    this.userModels = json
  }
}

export default ModelGallery
