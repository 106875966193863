import { useEffect, useRef } from 'react'

export function useEffectAfterMount(func, deps) {
  const first = useRef(true)

  useEffect(() => {
    if (!first.current) func()
    else first.current = false
  }, deps)
}
