import { useHistory } from 'react-router-dom'
import { action, flow } from 'mobx'
import rootStore, { useStore } from '~/src/app/store'
import { closestElement } from '~/src/utils/general'
import toaster from '~/src/features/toaster/toaster'
import { pull, indexOf } from 'lodash'
import CustomIcon from '~/src/app/components/Icon'
import { isDistributionProtected } from '~/src/features/backend/content'
import { env } from '~/src/features/backend/env'
import {
  TrainingDoesNotExist,
  OldVersionTrainingError,
} from '~/src/features/persistence/errors'

export function useTraining() {
  const { training } = useStore()
  return training
}

export function useStepsSequence() {
  const { training } = useStore()
  return training.stepSequence
}

export function useStepCount() {
  return useStepsSequence().length
}

export function useUpdateTrainingThumbnail() {
  const { training, screenshots } = useStore()
  return flow(function* () {
    training.thumbnail = yield screenshots.generateThumbnail(
      'training-thumbnail.png',
    )
    training.hasCustomThumbnail = true
  })
}

export function useStep(stepId) {
  const { training } = useStore()
  return training.steps[stepId]
}

export function useSelectedStepId() {
  const { appState } = useStore()
  return [appState.selectedStepId, appState.setSelectedStepId]
}

export function useSelectedStep() {
  const [selectedStepId] = useSelectedStepId()
  return useStep(selectedStepId)
}

export function useAddStep() {
  const { training, screenshots } = useStore()
  const [selectedStep, setSelectedStep] = useSelectedStepId()
  return flow(function* () {
    const step = training.cloneStep(selectedStep)
    setSelectedStep(step.id)
    step.camera = null
    step.thumbnail = yield screenshots.generateThumbnail(
      `${selectedStep}-thumbnail.png`,
    )
  })
}

export function useRemoveStep() {
  const { training, undo } = useStore()
  const stepSequence = useStepsSequence()
  const [selectedStepId, setSelectedStepId] = useSelectedStepId()
  return action(stepId => {
    if (stepSequence.length <= 1) return
    if (stepId === selectedStepId) {
      setSelectedStepId(closestElement(stepSequence, stepId))
    }
    undo.saveSnapshot('Step restored')
    training.removeStep(stepId)
    // TODO: model repository is *not* saved to undo.snapshot yet!
    // animator.runAfterTransition(() => modelRepository.disposeOrphans())
    toaster.show({
      icon: <CustomIcon icon="trash" />,
      intent: 'danger',
      message: 'Step deleted',
      timeout: 1500,
    })
  })
}

export function useMoveStep() {
  const { training } = useStore()
  return action((stepToMove, refStep, goesBefore = false) => {
    pull(training.stepSequence, stepToMove)
    const idx = indexOf(training.stepSequence, refStep)
    training.stepSequence.splice(idx + (goesBefore ? 0 : 1), 0, stepToMove)
  })
}

export function useSaveTraining() {
  const { training, persistence, appState } = useStore()
  return flow(function* () {
    // appState.setLoading(json.id, 0)
    yield persistence.saveTraining()
    // appState.clearLoading(json.id)
    toaster.show({
      icon: <CustomIcon icon="documentCheck" />,
      intent: 'success',
      message: 'Visualization saved',
      timeout: 1500,
    })
  })
}

export function useSaveCamera(step) {
  const { screenshots, camera, undo } = useStore()
  return flow(function* (e) {
    e.stopPropagation()
    undo.saveSnapshot()
    if (!step.camera) {
      step.camera = camera.getPose()
      step.thumbnail = yield screenshots.generateThumbnail(
        `${step.id}-thumbnail.png`,
      )
      toaster.show({
        icon: <CustomIcon icon="camera" />,
        message: 'Camera position saved to step',
        timeout: 1500,
        intent: 'primary',
      })
    } else {
      step.camera = null
      toaster.show({
        icon: <CustomIcon icon="camera" />,
        message: 'Camera position removed from step',
        timeout: 1500,
        intent: 'primary',
      })
    }
  })
}

export const resetAllState = flow(function* () {
  const { labels, transitions, modelRepository, appState } = rootStore
  modelRepository.disposeAllModels()
  yield modelRepository.initializeFromTraining()
  transitions.reset()
  labels.reset()
  appState.selectFirstStep()
})

export function useLoadTrainingForPlayer() {
  const { training, persistence } = useStore()
  const history = useHistory()
  return flow(function* (tokenOrId, distribution = true) {
    try {
      let json
      if (distribution) {
        if (yield isDistributionProtected(tokenOrId))
          json = yield loadProtectedTraining(tokenOrId)
        else {
          json = yield persistence.loadTrainingFromDistribution(tokenOrId)
        }
      } else {
        json = yield persistence.loadPublicTraining(tokenOrId)
      }
      training.readJSON(json)
      yield resetAllState()
    } catch (err) {
      console.error(err)
      if (err instanceof TrainingDoesNotExist) history.push('/not-found')
      else if (err instanceof OldVersionTrainingError) {
        window.location.href = `${env.redirectorURL}/${tokenOrId}`
      } else throw err
    }
  })
}

export function useLoadTraining() {
  const { training, persistence } = useStore()
  const history = useHistory()
  return flow(function* (trainingId) {
    try {
      yield persistence.loadTraining(trainingId)
      yield resetAllState()
    } catch (err) {
      if (err instanceof TrainingDoesNotExist) history.push('/not-found')
      else throw err
    }
  })
}

// --- utils

export async function loadProtectedTraining(token) {
  while (true) {
    const userInput = window.prompt(
      'This content is protected. Enter the password: ',
    )
    try {
      return await rootStore.persistence.loadTrainingFromDistribution(
        token,
        userInput,
      )
    } catch (e) {
      console.error(e)
    }
  }
}
