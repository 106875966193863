import {
  Intent,
  Checkbox,
  FormGroup,
  InputGroup,
  Button,
  Classes,
  Spinner,
  Card,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import validate from 'validate.js'
import Header from '../manager/Header'

// -------------------------
// form validation

const constraints = {
  email: { presence: { allowEmpty: false }, email: true },
  password: { presence: true, length: { minimum: 6 } },
}

function validateForm(values) {
  return validate(values, constraints)
}

// -------------------------
// ui component

const LoginPageView = ({ initialValues, onSubmit }) => (
  <div className="auth-container">
    <Header hasLogout={false} />
    <Card className="auth-card">
      <h2 className="auth-title">Sign in</h2>
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={onSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="auth-form">
            <FormGroup
              label="Email Address"
              labelInfo="*"
              labelFor="email"
              className={isSubmitting && Classes.SKELETON}
              intent={touched && errors.email && Intent.DANGER}
              helperText={touched && errors.email && errors.email[0]}
            >
              <InputGroup
                id="email"
                placeholder="john.smith@acme.com"
                type="email"
                value={values.email}
                onChange={handleChange}
                intent={touched && errors.email && Intent.DANGER}
                large
              />
            </FormGroup>
            <FormGroup
              label="Password"
              labelInfo="*"
              labelFor="password"
              className={isSubmitting && Classes.SKELETON}
              intent={touched.password && errors.password && Intent.DANGER}
              helperText={touched.password && errors.password}
            >
              <InputGroup
                id="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                intent={touched.password && errors.password && Intent.DANGER}
                large
              />
            </FormGroup>
            <Checkbox
              label="Remember"
              id="remember"
              className={isSubmitting && Classes.SKELETON}
              checked={values.remember}
              onChange={handleChange}
            />
            {isSubmitting ? (
              <Spinner />
            ) : (
              <Button fill intent="primary" large onClick={handleSubmit}>
                Sign In
              </Button>
            )}
          </form>
        )}
      </Formik>
      <Link className="auth-link" to="/auth/password-recovery">
        Forgot password
      </Link>
    </Card>
  </div>
)

export default LoginPageView
