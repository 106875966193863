import { makeObservable, action, observable } from 'mobx'

class KVStore {
  constructor() {
    makeObservable(this, { set: action })
  }

  assertObservable(key, initialValue) {
    if (!(key in this)) {
      this[key] = initialValue
      makeObservable(this, { [key]: observable })
    }
  }

  set(key, value) {
    this.assertObservable(key, value)
    this[key] = value
  }

  get(key, initialValue) {
    this.assertObservable(key, initialValue)
    return this[key]
  }

  useSlot(key, initialValue) {
    this.assertObservable(key, initialValue)
    return [
      this[key],
      action(value => {
        this[key] = value
      }),
    ]
  }
}

export default KVStore
