import { makeAutoObservable, flow } from 'mobx'
import { wait } from '~/src/utils/general'
import { assign, indexOf } from 'lodash'
import rootStore from '~/src/app/store'

class PlayerStore {
  autoPlay = false

  constructor({ appState, training }) {
    makeAutoObservable(this, {})
    assign(this, { appState, training })
  }

  start() {
    this.autoPlay = this.training.autoPlay
    this.maybePlayNextStep()
  }

  getCurrentStepInfo() {
    const { appState, training } = this
    const idx = indexOf(training.stepSequence, appState.selectedStepId)
    const total = training.stepSequence.length
    return { idx, total }
  }

  manuallyNavigateToNextStep() {
    this.autoPlay = false
    this.navigateToNextStep()
  }

  navigateToNextStep() {
    const { appState, training } = this
    const { idx } = this.getCurrentStepInfo()
    rootStore.animator.stopActiveTransition()
    // rootStore.transitions.halt()
    appState.selectedStepId = training.stepSequence[idx + 1]
  }

  manuallyNavigateToPrevStep() {
    this.autoPlay = false
    this.navigateToPrevStep()
  }

  navigateToPrevStep() {
    const { appState, training } = this
    const { idx } = this.getCurrentStepInfo()
    rootStore.animator.stopActiveTransition()
    // rootStore.transitions.halt()
    appState.selectedStepId = training.stepSequence[idx - 1]
  }

  isForwardNavigationPossible() {
    const { idx, total } = this.getCurrentStepInfo()
    return idx < total - 1
  }

  isBackNavigationPossible() {
    const { idx } = this.getCurrentStepInfo()
    return idx > 0
  }

  maybePlayNextStep = flow(function* () {
    const { appState, training } = this
    if (
      appState.mode !== 'editor' &&
      this.autoPlay &&
      this.isForwardNavigationPossible()
    ) {
      yield wait(training.transitionDuration)
      // check again to see if it changed after the wait
      if (this.autoPlay) this.navigateToNextStep()
    } else {
      this.autoPlay = false
    }
  })

  onTransitionFinished() {
    this.maybePlayNextStep()
  }
}

export default PlayerStore
