import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '~/src/app/store'
import { Drawer } from '@blueprintjs/core'
import SettingsPanel from '~/src/features/settingsPanel/SettingsPanel'
import ModelGalleryPanel from '~/src/features/modelGallery/ModelGalleryPanel'

const LeftSidebarDrawer = () => {
  const { appState } = useStore()
  const drawerContainer = useRef()
  const drawer = appState.sidebarDrawer
  const handleClose = () => (appState.sidebarDrawer = null)
  const drawerProps = {
    position: 'left',
    onClose: handleClose,
    portalContainer: drawerContainer.current,
  }
  return (
    <div className="left-sidebar-drawer" ref={drawerContainer}>
      <Drawer {...drawerProps} size="350px" isOpen={drawer === 'settings'}>
        <SettingsPanel />
      </Drawer>
      <Drawer {...drawerProps} size="85%" isOpen={drawer === 'add-model'}>
        <ModelGalleryPanel />
      </Drawer>
    </div>
  )
}

export default observer(LeftSidebarDrawer)
