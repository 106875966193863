import { observer } from 'mobx-react-lite'
import ModelGalleryItems from './ModelGalleryItems'
import { useStore } from '~/src/app/store'
import { get, map, concat } from 'lodash'

const StockModelGalleryItems = ({ category, nameFilter }) => {
  const { modelGallery } = useStore()
  let items = []
  if (category === 'all') {
    items = concat(...map(modelGallery.commonModels, 'items'))
  } else {
    items = get(modelGallery.commonModels, [category, 'items'], [])
  }
  items = items.filter(i =>
    i.name.toLowerCase().includes(nameFilter.toLowerCase()),
  )
  return (
    <ModelGalleryItems
      items={items}
      emptyTitle="Empty gallery"
      emptyText=" For now the gallery is empty. It will be filled with Aucta models in the future."
    />
  )
}

export default observer(StockModelGalleryItems)
