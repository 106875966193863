import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Switch, InputGroup, Icon } from '@blueprintjs/core'
import labelTool from '~/src/features/tools/label/labelTool'

const AnnotationLabelSubmenu = () => {
  const hasLabel = labelTool.hasLabel()
  const label = labelTool.getLabel()
  const onToggleChange = action(e => {
    if (!e.target.checked) labelTool.removeLabel()
    else labelTool.activateLabel()
  })
  const onInputChange = action(e => {
    labelTool.setLabel(e.target.value)
  })
  return (
    <div className="annotation-label-submenu">
      <Switch label="Show label" checked={hasLabel} onChange={onToggleChange} />
      <InputGroup
        leftElement={<Icon icon="tag" />}
        placeholder="Annotation..."
        disabled={!hasLabel}
        value={label || ''}
        onChange={onInputChange}
      />
    </div>
  )
}

export default observer(AnnotationLabelSubmenu)
