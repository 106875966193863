import { makeObservable, observable, action, runInAction } from 'mobx'
import { Engine } from '@babylonjs/core/Engines/engine'
import { Scene } from '@babylonjs/core/scene'
import { Vector3, Matrix } from '@babylonjs/core/Maths/math'
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera'
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents'
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator'
import '@babylonjs/loaders/glTF'
// for occlusion queries
import '@babylonjs/core/Rendering/boundingBoxRenderer'
import '@babylonjs/core/Engines/Extensions/engine.occlusionQuery'
// for outlines
import '@babylonjs/core/Rendering/outlineRenderer'
// import "@babylonjs/core/Rendering/edgesRenderer"
// import "@babylonjs/core/Layers/effectLayerSceneComponent"
import { ScreenshotTools } from '@babylonjs/core/Misc/screenshotTools'
// import { UtilityLayerRenderer } from "@babylonjs/core/Rendering/utilityLayerRenderer"
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent'
import '@babylonjs/core/Helpers/sceneHelpers'

import initializeScene from './initializeScene'
import { prepareBlenderModel } from './blenderModelTools'
import HighlightManager from './highlightManager'
import { forEach } from 'lodash'

// testing
import { GroundBuilder } from '@babylonjs/core/Meshes/Builders/groundBuilder'
// import '@babylonjs/core/Debug/debugLayer'
// import "@babylonjs/inspector";

// debug
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager'
import { SphereBuilder } from '@babylonjs/core/Meshes/Builders/sphereBuilder'
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera'

class SceneManagerStore {
  cameraPose = { position: null, target: null }
  canvas = null
  engine = null
  scene = null
  camera = null
  ground = null
  shadowGenerator = null
  scale = 1
  // highlightLayer = null
  // utilityLayer = null
  // utilities
  highlightManager = new HighlightManager()
  // flag for alt+clicking to set up the camera center
  isCtrlPressed = false

  constructor(rootStore) {
    this.rootStore = rootStore
    this.ui = rootStore.ui
    this.persistence = rootStore.persistence
    window.sm = this
    makeObservable(this, {
      cameraPose: observable,
      scene: observable.ref,
    })
  }

  async initialize(canvas) {
    this.canvas = canvas
    this.engine = new Engine(canvas, true, { stencil: true })
    window.engine = this.engine
    this.scene = new Scene(this.engine)
    this.scene.useRightHandedSystem = true
    this.camera = new ArcRotateCamera(
      'camera',
      0,
      0,
      30,
      new Vector3(0, 1, 0),
      this.scene,
      true,
    )
    this.camera.layerMask = 1
    this.camera.pinchPrecision = 100
    // this.utilityLayer = new UtilityLayerRenderer(this.scene)
    this.engine.runRenderLoop(this.update)
    window.addEventListener('resize', () => this.engine.resize())
    initializeScene(this)
    // this.scene.onPointerObservable.removeCallback(this.handleClick)
    this.scene.onPointerObservable.add(this.handleClick)
    document.addEventListener('keydown', this.handleKey)
    document.addEventListener('keyup', this.handleKey)
    this.scene.onKeyboardObservable.add(this.handleKey)
  }

  destroy() {
    this.scene.onPointerObservable.removeCallback(this.handleClick)
    this.scene.onKeyboardObservable.removeCallback(this.handleKey)
    document.removeEventListener('keydown', this.handleKey)
    document.removeEventListener('keyup', this.handleKey)
    console.log('* sceneManager DESTRUCTOR')
    this.scene.dispose()
    this.engine.dispose()
    this.camera.dispose()
  }

  setCameraTarget(point) {
    this.rootStore.camera.setTarget(point)
  }

  handleClick = ({ type, pickInfo }) => {
    // Alt + right click resets the camera position
    if (
      this.isCtrlPressed &&
      type === PointerEventTypes.POINTERPICK &&
      pickInfo.hit &&
      pickInfo.pickedMesh
    ) {
      const point = pickInfo.pickedPoint
      this.setCameraTarget(point)
    }
  }

  handleKey = event => {
    if (event.code === 'ControlLeft') {
      if (event.type === 'keydown') this.isCtrlPressed = true
      else this.isCtrlPressed = false
    }
  }

  projectShadows(_mesh) {
    // TODO: pending
    // this.shadowGenerator.addShadowCaster(mesh, true)
  }

  disposeShadows(_mesh) {
    // TODO: pending
    // this.shadowGenerator.removeShadowCaster(mesh, true)
  }

  update = () => {
    this.scene.render()
  }

  highlight(mesh, color) {
    const { highlightManager } = this
    // TODO: babylon highlighting is not very good and doesn't play well with
    // instanced meshes (example: race.glb model), highlight all the instances
    // at the same time when you try to highlight any one of them.
    // The solution:
    // 1. *replace the highlighted node with a clone*.
    // 2. restore the original on un-highlight.
    // 3. make sure to propagate the transformations (make it seamless)
    highlightManager.highlightMesh(mesh, color)
    forEach(mesh.getChildMeshes(), m =>
      highlightManager.highlightMesh(m, color),
    )
  }

  unhighlight(mesh) {
    const { highlightManager } = this
    highlightManager.unhighlightMesh(mesh)
    forEach(mesh.getChildMeshes(), m => highlightManager.unhighlightMesh(m))
  }

  getNodeScreenPosition(node) {
    const { scene, camera, engine } = this
    return Vector3.Project(
      node.getAbsolutePosition(),
      Matrix.IdentityReadOnly,
      scene.getTransformMatrix(),
      camera.viewport.toGlobal(
        engine.getRenderWidth(),
        engine.getRenderHeight(),
      ),
    )
  }

  disableMaterialsPrePass() {
    this.scene.materials.forEach(m => {
      m.checkReadyOnEveryCall = false
      m.needDepthPrePass = false
    })
  }

  async takeScreenshot(size) {
    return new Promise(resolve => {
      ScreenshotTools.CreateScreenshotUsingRenderTarget(
        this.engine,
        this.camera,
        size,
        resolve,
      )
    })
  }
}

export default SceneManagerStore
