import { getNodePath, getObjectIdFromNode } from '~/src/utils/nodes'
import { updateWith, get, set } from 'lodash'

export function setToolState(node, step, tool, value) {
  const toolsStates = getAllToolsStates(node, step)
  return set(toolsStates, [tool.key], value)
}

export function getAllToolsStates(node, step) {
  const objectId = getObjectIdFromNode(node)
  const object = step.objects[objectId]
  if (!object) throw new Error('Unkown selected object:', objectId)
  const path = [...getNodePath(node), 'tools']
  return get(object, path, safeStoreProxy(object, path))
}

export function getToolState(node, step, tool) {
  const toolsStates = getAllToolsStates(node, step)
  return get(toolsStates, [tool.key], safeStoreProxy(toolsStates, [tool.key]))
}

export function removeToolState(node, step, tool) {
  const toolsStates = getAllToolsStates(node, step)
  delete toolsStates[tool.key]
}

// makes sure the tool has a properly initialized store on write
export function safeStoreProxy(object, path) {
  const handler = {
    get(target, prop) {
      return get(target, [...path, prop])
    },
    set(target, prop, value) {
      set(target, [...path, prop], value)
      return true
    },
  }
  return new Proxy(object, handler)
}
