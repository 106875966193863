import React, { useEffect, useState } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStore } from '~/src/app/store'
import { isEmpty } from 'lodash'
import cx from 'classnames'

const LabelOverlay = () => {
  const { labels, sceneManager, appState } = useStore()
  const { labelsVisible } = appState

  // TODO: this is more efficient, but in some cases does not update enough
  // const markerPoints = labels.getProjectedLabels()
  // // force recalculation of points when camera changes
  // const [_, rerender] = useState(false)
  // const update = useCallback(() => rerender(Date.now()), [rerender])
  // useEffect(() => {
  //   update()
  //   const { onViewMatrixChangedObservable } = sceneManager.camera
  //   onViewMatrixChangedObservable.add(update)
  //   return () => {
  //     onViewMatrixChangedObservable.removeCallback(update)
  //   }
  // }, [update, selectedStepId])

  // NOTE: this method is wasteful, but at least it is correct

  const [markerPoints, setMarkerPoints] = useState([])
  const onUpdate = () => setMarkerPoints(labels.getProjectedLabels())

  useEffect(() => {
    const { scene } = sceneManager
    if (!labelsVisible || !scene) return
    onUpdate()
    scene.onBeforeRenderObservable.add(onUpdate)
    return () => {
      scene.onBeforeRenderObservable.removeCallback(onUpdate)
      setMarkerPoints([])
    }
  }, [sceneManager.scene, labelsVisible])

  // highlight node on hover
  const handleMouseEnter = node =>
    action(() => {
      const { highlightManager } = sceneManager
      highlightManager.highlightMesh(node)
    })
  const handleMouseLeave = node =>
    action(() => {
      const { highlightManager } = sceneManager
      highlightManager.unhighlightMesh(node)
    })

  // the thing itself

  return (
    <div className="annotation-marker-container">
      {labelsVisible &&
        markerPoints.map(markerPoint => {
          const { id, text, visible, x, y, node } = markerPoint
          const classNames = cx('marker-point', { visible })
          const styles = { transform: `translate(${x}px, ${y}px)` }
          return (
            <div
              className={classNames}
              key={id}
              style={styles}
              onMouseEnter={handleMouseEnter(node)}
              onMouseLeave={handleMouseLeave(node)}
            >
              {!isEmpty(text) && <div className="label">{text}</div>}
            </div>
          )
        })}
    </div>
  )
}

export default observer(LabelOverlay)
