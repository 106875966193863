import { NonIdealState, Icon } from '@blueprintjs/core'
import { env } from '~/src/features/backend/env'

const NotFoundPageView = () => (
  <div className="notFound-message">
    <NonIdealState
      icon="delete"
      title="Nothing to see here"
      description="The page you're trying to access is empty. If you're trying to edit a training, please use the link below to go to the Manager and try again."
      action={
        <a className="link" href={env.managerURL}>
          <Icon icon="arrow-left" />
          Go back to the manager
        </a>
      }
    />
  </div>
)

export default NotFoundPageView
