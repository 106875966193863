import { Color3 } from '@babylonjs/core/Maths/math'
import { get, forEach, assign, update, pick } from 'lodash'

const DEFAULT_COLOR = new Color3(135 / 255, 206 / 255, 250 / 255)

class HighlightManager {
  highlights = {}

  highlightMesh(mesh, color = DEFAULT_COLOR) {
    // console.log("+ highlight:", mesh.id)
    if (!mesh) return
    update(this.highlights, [mesh.uniqueId], (highlightHistory = []) => {
      const snapshot = pick(mesh, ['renderOverlay', 'overlayColor'])
      assign(mesh, { renderOverlay: true, overlayColor: color })
      return [...highlightHistory, snapshot]
    })
  }

  clearHighlightHistory(mesh) {
    delete this.highlights[mesh.uniqueId]
    forEach(mesh.getChildMeshes(), m => this.clearHighlightHistory(m))
  }

  unhighlightMesh(mesh) {
    // console.log("- unhighlight:", mesh.id)
    if (!mesh) return
    const history = get(this.highlights, [mesh.uniqueId], [])
    const prevState = history.pop() || { renderOverlay: false }
    assign(mesh, prevState)
  }
}

export default HighlightManager
