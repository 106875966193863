import { env } from '../env'
import axios from 'axios'
import { getInfo } from '../utils/userSystemInfo'

export async function log(message, auctaAppName, logInConsole = true) {
  if (logInConsole) console.log(message)
  const logData = {
    message: message,
    logGroup: `${auctaAppName}`,
  }
  const response = await axios.post(`/log`, logData, {
    baseURL: env.logAPI,
  })
  return response.data
}

export async function logCritical(
  message,
  appName = '',
  methodName = '',
  stateObject,
) {
  console.error(message)
  const info = await getInfo()
  const logData = {
    message: message,
    ...info,
    origin: appName,
    logGroup: appName,
    method: methodName,
    stateObject,
  }
  try {
    const response = await axios.post(`/log-critical`, logData, {
      baseURL: env.logAPI,
    })
    return response.data
  } catch (e) {
    console.log(`*Error: Failed to log critical error (${e.message}) `)
  }
}
