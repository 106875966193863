import { observer } from 'mobx-react-lite'
import { Icon } from '@blueprintjs/core'
import { useStore } from '~/src/app/store'
import { isEmpty, indexOf } from 'lodash'
import cx from 'classnames'
import Tooltip from '~/src/utils/components/Tooltip'

const FloatingItem = ({ disabled, onClick, icon, rotation, tooltip }) => (
  <div
    className={cx('menu-item-container', { disabled })}
    style={{ transform: `rotate(${rotation}deg)` }}
    onClick={disabled ? undefined : onClick}
  >
    <div style={{ transform: `rotate(${-rotation}deg)` }}>
      <Tooltip content={tooltip}>
        <div className="menu-item">
          <Icon icon={icon} />
        </div>
      </Tooltip>
    </div>
  </div>
)

const AttributePropagationMenu = ({ controller }) => {
  const { toolbar, training, appState } = useStore()
  const propagationEnabled = !isEmpty(toolbar.selectedAttributes)
  const currentStepIdx = indexOf(training.stepSequence, appState.selectedStepId)
  const isFirst = currentStepIdx === 0
  const isLast = currentStepIdx === training.stepSequence.length - 1
  return (
    <div
      className={cx('attribute-propagation-menu', {
        hidden: !controller.isAttributeMenuOpen,
      })}
    >
      <FloatingItem
        onClick={toolbar.propagateAttributesNext}
        disabled={!propagationEnabled || isLast}
        icon="chevron-right"
        rotation={43}
        tooltip="Propagate attributes to next step"
      />

      <FloatingItem
        onClick={toolbar.propagateAttributesForward}
        disabled={!propagationEnabled || isLast}
        icon="double-chevron-right"
        rotation={86}
        tooltip="Propagate attributes to all following steps"
      />

      <FloatingItem
        onClick={toolbar.propagateAttributesEverywhere}
        disabled={!propagationEnabled}
        target="everywhere"
        icon="arrows-horizontal"
        tooltip="Propagate attributes to every step"
      />
      <FloatingItem
        onClick={toolbar.propagateAttributesPrev}
        disabled={!propagationEnabled || isFirst}
        icon="chevron-left"
        rotation={-43}
        tooltip="Propagate attributes to previous step"
      />

      <FloatingItem
        onClick={toolbar.propagateAttributesBackwards}
        disabled={!propagationEnabled || isFirst}
        icon="double-chevron-left"
        rotation={-86}
        tooltip="Propagate attributes to all previous steps"
      />

      <FloatingItem
        onClick={toolbar.pasteAttributes}
        icon="import"
        rotation={129}
        tooltip="Paste attributes"
      />

      <FloatingItem
        onClick={toolbar.copyAttributes}
        icon="export"
        rotation={-129}
        tooltip="Copy attributes"
      />
    </div>
  )
}

export default observer(AttributePropagationMenu)
