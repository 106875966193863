import { observer } from 'mobx-react-lite'
import { ProgressBar } from '@blueprintjs/core'
import Cloak from '~/src/utils/components/Cloak'
import { useStore } from '~/src/app/store'

const LoadingScreen = ({ forced, infinite }) => {
  const { appState } = useStore()
  return (
    <Cloak isVisible={forced || appState.isLoading()}>
      <div className="loading-screen">
        <div className="progress-bar">
          <ProgressBar
            value={infinite ? undefined : appState.getLoadingProgress()}
            stripes={false}
            intent="primary"
          />
        </div>
      </div>
    </Cloak>
  )
}

export default observer(LoadingScreen)
