import { useState, useEffect, useCallback } from 'react'
import { useStore } from '~/src/app/store'
import { isEmpty } from 'lodash'

export const modes = {
  EMPTY: 'EMPTY',
  READY: 'READY',
  PROGRESS: 'PROGRESS',
  UPLOAD: 'UPLOAD',
}

export const trainingAssetPath = (training, persistence) => {
  return persistence.trainingPath(training.id, 'uploads/')
}

export const useFileUploader = ({
  fileName,
  folder,
  onFinish,
  onStart,
  onReset,
  onDelete,
  onError = console.error,
}) => {
  const { training, persistence } = useStore()
  const [mode, setMode] = useState(modes.EMPTY)
  const [progress, setProgress] = useState(0)

  // update the mode if we got data after render

  useEffect(() => {
    if (mode === modes.EMPTY && !isEmpty(fileName)) {
      setMode(modes.READY)
    } else if (isEmpty(fileName)) {
      setMode(modes.EMPTY)
    }
  }, [fileName])

  const handleUpload = useCallback(
    async file => {
      if (!training.id) throw new Error('Invalid training!')
      setMode(modes.PROGRESS)
      setProgress(0)
      onStart && onStart()
      try {
        const res = await persistence.uploadFile(file, folder, setProgress)
        onFinish && onFinish(file.name, res.Key)
        setMode(modes.READY)
      } catch (e) {
        onError(e)
      }
    },
    [setMode, setProgress, onFinish, training.id],
  )

  const handleReset = useCallback(() => {
    onReset && onReset()
    setMode(modes.UPLOAD)
  }, [setMode, onReset])

  const handleDelete = useCallback(() => {
    // TODO: actually delete the file!
    onDelete && onDelete()
    setMode(modes.UPLOAD)
  }, [setMode, onDelete])

  return { handleUpload, handleReset, handleDelete, mode, progress }
}
