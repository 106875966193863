import { action, makeAutoObservable } from 'mobx'
import { isEmpty, get, assign, forEach, assign, union, keys } from 'lodash'
import { findNode, isInstancedMesh } from '~/src/utils/nodes'
import ToolFactory from '~/src/features/tools/toolFactory'
import ease from '~/src/utils/easing'

class AnimatorStore {
  // control

  constructor({ training, sceneManager, modelRepository }) {
    makeAutoObservable(this, {})
    assign(this, { training, sceneManager, modelRepository })
  }

  startThread() {
    const { scene } = this.sceneManager
    scene.onBeforeRenderObservable.add(this.onBeforeRender)
  }

  stopThread() {
    const { scene } = this.sceneManager
    scene.onBeforeRenderObservable.removeCallback(this.onBeforeRender)
  }

  // animation engine

  isTransitionActive = false
  transitionStartedAt = 0
  // state
  materializations = {}
  dematerializations = {}
  toolTransitions = []
  originStep = null
  targetStep = null
  // dealyed thunks
  toRunAfterTransition = []
  toRunBeforeTransition = []

  resetTransitionState() {
    this.materializations = {}
    this.dematerializations = {}
    this.toolTransitions = []
    this.toRunAfterTransition = []
    this.toRunBeforeTransition = []
  }

  onBeforeRender = action(() => {
    // this runs on every frame
    if (!this.isTransitionActive) return
    if (!isEmpty(this.toRunBeforeTransition)) {
      forEach(this.toRunBeforeTransition, thunk => thunk())
      this.toRunBeforeTransition = []
    }
    const now = Date.now()
    const transitionDuration = this.training.transitionDuration
    const delta = Math.min(
      1,
      (now - this.transitionStartedAt) / transitionDuration,
    )
    // materializations
    const tIn = ease(delta, 'circle', false, true)
    const tOut = ease(1 - delta, 'circle', true, false)
    forEach(this.materializations, model => {
      this.fadeModel(model, tIn)
    })
    // dematerializations
    forEach(this.dematerializations, model => {
      this.fadeModel(model, tOut)
    })
    // tool
    forEach(this.toolTransitions, toolTransition => {
      // TODO: maybe the transitions should be longer than materializations
      this.applyToolTransition(toolTransition, tIn)
    })
    // cleanup
    if (delta >= 1) return this.onTransitionEnded()
  })

  applyToolTransition(toolTransition, t) {
    const { tool, originState, targetState, refState, node } = toolTransition
    tool.applyToNode(node, originState, targetState, refState, t)
  }

  onTransitionStart() {
    forEach(this.dematerializations, (_model, objectId) => {
      this.onBeforeDematerializeStepObject(
        this.originStep,
        this.targetStep,
        objectId,
      )
    })
    forEach(this.toolTransitions, toolTransition => {
      const { tool, originState, targetState, refState, node } = toolTransition
      if (tool.onBeforeTransition) {
        tool.onBeforeTransition(node, originState, targetState, refState)
      }
    })
  }

  onTransitionEnded() {
    const { modelRepository } = this
    this.isTransitionActive = false
    // remove dematerializations from scene
    forEach(this.dematerializations, (model, objectId) => {
      this.fadeModel(model, 0)
      this.resetStepObject(this.originStep, this.targetStep, objectId)
      modelRepository.removeModelFromScene(objectId)
    })
    // make sure all materializations are completely solid
    forEach(this.materializations, model => {
      this.fadeModel(model, 1)
    })
    // tool cleanup
    forEach(this.toolTransitions, toolTransition => {
      const { tool, originState, targetState, refState, node } = toolTransition
      // make sure it arrives at their final state
      this.applyToolTransition(toolTransition, 1)
      if (tool.onAfterTransition) {
        tool.onAfterTransition(node, originState, targetState, refState)
      }
    })
    // run all delayed thunks
    forEach(this.toRunAfterTransition, thunk => thunk())
    // all done!
    this.resetTransitionState()
  }

  // TODO: this should be an utility or something
  fadeModel(model, t) {
    try {
      if (model.material) {
        model.material.needDepthPrePass = t !== 1 && t !== 0
      }
      model.visibility = t
      forEach(model.getChildMeshes(), mesh => {
        if (isInstancedMesh(mesh) || mesh.deleted) return
        if (mesh.material) {
          mesh.material.needDepthPrePass = t !== 1 && t !== 0
        }
        mesh.visibility = t
      })
    } catch (e) {
      console.log('> Captured error on fadeModel')
      // damned instanced meshes throw if we try to change visibility
      // ...
    }
  }

  stopActiveTransition() {
    this.isTransitionActive = false
    this.onTransitionEnded()
  }

  startTransition(originStep, targetStep) {
    if (this.isTransitionActive) {
      this.stopActiveTransition()
    }
    this.isTransitionActive = true
    this.transitionStartedAt = Date.now()
    this.originStep = originStep
    this.targetStep = targetStep
    this.runBeforeTransition(() => this.onTransitionStart())
  }

  runAfterTransition(thunk) {
    this.toRunAfterTransition.push(thunk)
  }

  runBeforeTransition(thunk) {
    this.toRunBeforeTransition.push(thunk)
  }

  // [de]materializations of nodes are done through a tool

  // tool logic

  applyStepObject(originStep, targetStep, objectId) {
    // immediately apply the final state
    this.forEachToolTransitionInObject(
      originStep,
      targetStep,
      objectId,
      this.instantToolTransition,
    )
  }

  resetStepObject(originStep, targetStep, objectId) {
    this.forEachToolTransitionInObject(
      originStep,
      targetStep,
      objectId,
      this.resetToolTransitions,
    )
  }

  onBeforeDematerializeStepObject(originStep, targetStep, objectId) {
    this.forEachToolTransitionInObject(
      originStep,
      targetStep,
      objectId,
      this.prepareDematerialization,
    )
  }

  forEachToolTransitionInObject(originStep, targetStep, objectId, fn) {
    const originObject = originStep.getObject(objectId)
    const targetObject = targetStep.getObject(objectId)
    const rootNode = this.modelRepository.findNodeForId(objectId)
    this.forEachApplicableTool(
      originObject.tools,
      targetObject.tools,
      rootNode,
      fn,
    )
    // subnodes
    const allNodes = union(keys(originObject.nodes), keys(targetObject.nodes))
    forEach(allNodes, nodeId => {
      const node = findNode(rootNode, nodeId)
      const originTools = get(originObject, ['nodes', nodeId, 'tools'], {})
      const targetTools = get(targetObject, ['nodes', nodeId, 'tools'], {})
      this.forEachApplicableTool(originTools, targetTools, node, fn)
    })
  }

  forEachApplicableTool(originTools = {}, targetTools = {}, node, fn) {
    // console.log(
    //   ">> applying TOOLS:", toJS(originTools), "->", toJS(targetTools),
    //   "to node:", node
    // )
    const allTools = union(keys(originTools), keys(targetTools))
    forEach(allTools, toolKey => {
      const tool = ToolFactory.get(toolKey)
      const originState = originTools[toolKey]
      const targetState = targetTools[toolKey]
      fn(node, tool, originState, targetState)
    })
  }

  startToolTransition = (node, tool, originState, targetState) => {
    const toolTransition = { node, tool, originState, targetState }
    toolTransition.refState = tool.getRefState(node, originState, targetState)
    this.toolTransitions.push(toolTransition)
  }

  instantToolTransition = (node, tool, originState, targetState) => {
    if (!node || !tool) return
    const refState = tool.getRefState(node, originState, targetState)
    if (tool.onBeforeTransition)
      tool.onBeforeTransition(node, originState, targetState, refState)
    tool.applyToNode(node, originState, targetState, refState, 1)
    if (tool.onAfterTransition)
      tool.onAfterTransition(node, originState, targetState, refState)
  }

  resetToolTransitions(node, tool, originState, targetState) {
    tool.resetToOriginal(node, originState, targetState)
  }

  prepareDematerialization(node, tool, originState, targetState) {
    if (tool.onBeforeDematerialization) {
      tool.onBeforeDematerialization(node, originState, targetState)
    }
  }

  // interface

  materializeModel(objectId) {
    // console.log(">>> materialize:", objectId)
    const { modelRepository } = this
    const rootNode = modelRepository.findNodeForId(objectId)
    this.fadeModel(rootNode, 0)
    // apply all tools to the object before materializing
    this.applyStepObject(this.originStep, this.targetStep, objectId)
    modelRepository.addModelToScene(objectId)
    this.materializations[objectId] = rootNode
  }

  materializeNode(node) {
    this.materializations[node.uniqueId] = node
  }

  dematerializeModel(objectId) {
    // console.log("<<< dematerialize:", objectId)
    const { modelRepository } = this
    const rootNode = modelRepository.findNodeForId(objectId)
    this.dematerializations[objectId] = rootNode
  }

  dematerializeNode(node) {
    this.dematerializations[node.uniqueId] = node
  }

  transitionStepObject(originStep, targetStep, objectId) {
    // start an animated transition
    this.forEachToolTransitionInObject(
      originStep,
      targetStep,
      objectId,
      this.startToolTransition,
    )
  }
}

export default AnimatorStore
