import { action } from 'mobx'
import rootStore from '~/src/app/store'
import { Vector3 } from '@babylonjs/core/Maths/math'
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager'
import { RED, GREEN, BLUE, getPivotWrapper } from '~/src/utils/nodes'
import { getToolState, removeToolState } from '~/src/utils/tools'
import { disposeGizmoManager, styleScaleGizmo } from '../mixins'
import { assign, isEmpty } from 'lodash'

// debug
import { toJS } from 'mobx'

class ScaleTool {
  key = 'scale'

  attachToScene(node, state) {
    const wrapper = getPivotWrapper(node)
    this.setupGizmos(wrapper, state)
    rootStore.appState.hideLabels()
    console.log('>> attach to scene: ScaleTool', node, toJS(state))
  }

  dettachFromScene(node, _state) {
    this.gizmoManager.attachToMesh(null)
    disposeGizmoManager(this.gizmoManager)
    this.gizmoManager = null
    node.onAfterWorldMatrixUpdateObservable.removeCallback(
      this.updateToolbarPosition,
    )
    rootStore.appState.showLabels()
  }

  // gizmos

  setupGizmos(node, state) {
    const {
      undo,
      sceneManager: { scene },
    } = rootStore
    this.gizmoManager = new GizmoManager(scene, 2)
    this.gizmoManager.usePointerToAttachGizmos = false
    this.gizmoManager.scaleGizmoEnabled = true
    this.gizmoManager.gizmos.scaleGizmo.sensitivity = 3
    this.gizmoManager.attachToMesh(node)
    styleScaleGizmo(this.gizmoManager)
    // store original node position (if not already saved)
    const { scaleGizmo } = this.gizmoManager.gizmos
    scaleGizmo.onDragStartObservable.add(() => {
      undo.saveSnapshot()
      const startScale = node.scaling.clone()
      this.maybeStoreReferenceScale(state, startScale)
    })
    // calculate the total movement delta (against the stored original reference)
    scaleGizmo.onDragEndObservable.add(() => {
      const endScale = node.scaling.clone()
      this.storeTotalDelta(state, endScale)
      rootStore.appState.updateStepThumbnail()
    })
    // update the floating toolbar position when moving the part
    node.onAfterWorldMatrixUpdateObservable.add(this.updateToolbarPosition)
  }

  updateToolbarPosition = () => {
    const { toolbar } = rootStore
    toolbar.updateFloatingPosition()
  }

  // aux

  resetState(node, state) {
    assign(state, {
      originalScale: undefined,
      scale: undefined,
      delta: undefined,
    })
  }

  resetPartScale = action((node, step) => {
    // called from rotationTool#resetPartRotation
    const state = getToolState(node, step, this)
    this.resetState(node, state)
    removeToolState(node, step, this)
    // reflect in scene
    const refState = this.getRefState(node)
    const targetState = { scale: [1, 1, 1] }
    this.applyToNode(node, {}, targetState, refState, 1)
  })

  // data

  maybeStoreReferenceScale(state, startScale) {
    // never overwrite originalPosition if present!
    if (!state.originalScale) {
      state.originalScale = startScale.asArray()
    }
  }

  storeTotalDelta(state, endScale) {
    state.scale = endScale.asArray()
    state.delta = endScale
      .subtract(Vector3.FromArray(state.originalScale))
      .asArray()
  }

  // application

  applyToNode(node, originState = {}, targetState = {}, refState, t = 1) {
    if (isEmpty(originState) && isEmpty(targetState)) return
    const wrapper = getPivotWrapper(node)
    // if no target position, then restore the original position
    const { startScale } = refState
    const targetScale = Vector3.FromArray(
      targetState.scale || originState.originalScale || [1, 1, 1],
    )
    const scale = Vector3.Lerp(startScale, targetScale, t)
    wrapper.scaling.copyFrom(scale)
  }

  resetToOriginal(node, originState = {}, targetState = {}) {
    if (isEmpty(originState) && isEmpty(targetState)) return
    const wrapper = getPivotWrapper(node)
    const originalScale = Vector3.FromArray(
      targetState.originalScale || originState.originalScale || [1, 1, 1],
    )
    wrapper.scaling.copyFrom(originalScale)
  }

  getRefState(node, _originState, _targetState) {
    const wrapper = getPivotWrapper(node)
    return { startScale: wrapper.scaling.clone() }
  }
}

export default new ScaleTool()
