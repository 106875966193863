import { observer } from 'mobx-react-lite'
import { action } from 'mobx'
import { useStore } from '~/src/app/store'
import { Menu, MenuItem } from '@blueprintjs/core'
import { useEffect } from 'react'

const AncestorSelectorMenu = () => {
  const { toolbar, sceneManager } = useStore()
  // actions
  const handleClose = action(() => {
    toolbar.nodeAncestors = null
    return false
  })
  const highlightNode = node => sceneManager.highlight(node)
  const unhighlightNode = node => sceneManager.unhighlight(node)
  const handleSelect = action(node => {
    unhighlightNode(node)
    toolbar.selectNode(node)
    toolbar.nodeAncestors = null
  })

  useEffect(() => {
    if (toolbar.nodeAncestors) {
      window.addEventListener('click', handleClose)
    }
    return () => window.removeEventListener('click', handleClose)
  }, [toolbar.nodeAncestors])

  // render
  if (!toolbar.nodeAncestors) {
    return null
  } else {
    // const ancestors = reverse([...toolbar.nodeAncestors])
    const ancestors = toolbar.nodeAncestors
    const { x, y } = toolbar.eventCoordinates
    return (
      <div className="ancestor-selector-menu">
        <div className="overlay" onMouseDown={handleClose}>
          <div
            className="menu-container"
            style={{ left: `${x}px`, top: `${y}px` }}
          >
            <Menu>
              {ancestors.map((ancestor, i) => (
                <MenuItem
                  key={ancestor.id}
                  icon={
                    i === 0
                      ? 'layer-outline'
                      : i === ancestors.length - 1
                      ? 'cube'
                      : 'layer'
                  }
                  text={ancestor.name === '__root__' ? 'Model' : ancestor.name}
                  onMouseEnter={() => highlightNode(ancestor)}
                  onMouseLeave={() => unhighlightNode(ancestor)}
                  onMouseDown={e => {
                    handleSelect(ancestor)
                    e.stopPropagation()
                  }}
                />
              ))}
            </Menu>
          </div>
        </div>
      </div>
    )
  }
}

export default observer(AncestorSelectorMenu)
