import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { MenuItem } from '@blueprintjs/core'

const MenuButton = ({
  controller,
  icon,
  tool,
  children,
  onClick,
  tooltip,
  ...props
}) => (
  <>
    <MenuItem
      icon={icon}
      active={controller.isToolActive(tool)}
      onClick={onClick || action(() => controller.activateTool(tool))}
      {...props}
    >
      {children}
    </MenuItem>
  </>
)

export default observer(MenuButton)
