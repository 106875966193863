import { observer } from 'mobx-react-lite'
import { Navbar, NavbarGroup, H5, Icon } from '@blueprintjs/core'
import ModelGalleryItems from './ModelGalleryItems'
import UploadModel from './UploadModel'
import { useStore } from '~/src/app/store'
import { values } from 'lodash'

const UserModelGalleryItems = ({ nameFilter }) => {
  const { modelGallery } = useStore()
  const { loading, userModels } = modelGallery
  const items = values(userModels).filter(i =>
    i.name.toLowerCase().includes(nameFilter.toLowerCase()),
  )

  return (
    <>
      <Navbar className="user-model-navbar">
        <NavbarGroup>
          <UploadModel />
        </NavbarGroup>
        <NavbarGroup align="right" className="navbar-label">
          <H5>.GLB / 1GB max.</H5>
          <a className="help" href="#">
            <Icon icon="help" size="20px" color="gray" />
          </a>
        </NavbarGroup>
      </Navbar>
      <ModelGalleryItems
        items={items}
        isLoading={loading}
        onDelete={modelGallery.removeModelFromGallery}
        emptyTitle="Empty gallery"
        emptyText="There are no models in your gallery. You can upload a model by clicking on the 'Upload model' button"
      />
    </>
  )
}

export default observer(UserModelGalleryItems)
