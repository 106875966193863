import { useEffect } from 'react'
import EditorPageView from './EditorPageView'
import { reaction, flow } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useStore } from '~/src/app/store'
import { useLoadTraining } from '~/src/hooks/training'

const EditorPage = () => {
  const { id } = useParams()
  const {
    appState,
    sceneManager,
    training,
    modelGallery,
    transitions,
    toolbar,
  } = useStore()
  const { modelRepository, labels, undo } = useStore()
  const loadTraining = useLoadTraining()

  // load the training (id in url)
  useEffect(() => {
    const disposers = [
      // adjust selection when navigating between steps
      reaction(
        () => appState.selectedStepId,
        (selectedStepId, _previousSelectedStepId) => {
          console.log(
            'reaction> steps.selected',
            _previousSelectedStepId,
            '->',
            selectedStepId,
          )
          toolbar.unselectNode()
          transitions.onSelectedStepChange(selectedStepId)
          toolbar.nodeAncestors = null
        },
      ),

      // resize viewport when required
      reaction(
        () => [appState.loading['scene'], appState.mode],
        ([isLoading]) => {
          // make sure the enigne compensates for the sidebar correctly
          console.log('reaction> scene loading done or mode change')
          if (!isLoading) setTimeout(() => sceneManager.engine.resize(), 0)
        },
      ),

      // adjust the UI based on active mode
      reaction(
        () => [appState.mode],
        (mode, prevMode) => {
          // add a class to <body> that reflects the current UI mode
          document.body.classList.remove(`${prevMode}-mode`)
          document.body.classList.add(`${mode}-mode`)
        },
      ),

      // transition to selected step
      reaction(
        () => [appState.selectedStepId],
        () => {
          console.log('reaction> steps.selected (halt)')
          // TODO:
        },
      ),
    ]

    // init app state
    appState.editorMode()
    undo.reset()

    // fetch the training
    const doFetchTraining = flow(function* () {
      appState.setLoading('scene', 0)
      yield loadTraining(id)
      appState.clearLoading('scene')
    })
    doFetchTraining()

    // fetch the model gallery index
    const doFetchUserModels = flow(function* () {
      yield modelGallery.fetchUserModels()
    })
    doFetchUserModels()

    return () => {
      console.log('EditorPage: cleanup')
      modelRepository.disposeAllModels()
      training.dispose()
      transitions.disable()
      appState.reset()
      labels.reset()
      // cleanup mobx reactions
      for (const disposer of disposers) disposer()
    }
  }, [id])

  return <EditorPageView />
}

export default observer(EditorPage)
