import { flow } from 'mobx'
import rootStore from '~/src/app/store'
import { isRootNode } from '~/src/utils/nodes'
import { wait } from '~/src/utils/general'

class RemoveTool {
  key = 'remove'

  attachToScene = flow(function* (node, state) {
    const { training, appState, modelRepository, toolbar, undo, labels } =
      rootStore
    undo.saveSnapshot()
    const selectedStep = training.steps[appState.selectedStepId]
    if (isRootNode(node)) {
      const objectId = modelRepository.findIdForNode(node)
      selectedStep.removeObject(objectId)
      modelRepository.removeModelFromScene(objectId)
      // TODO: disabled until undo.snapshots store model repos
      // modelRepository.disposeOrphans()
    } else {
      state.hidden = true
      rootStore.transitions.onSelectedStepChange(appState.selectedStepId)
    }
    labels.removeLabel(node)
    labels.removeChildNodeLabels(node)
    toolbar.unselectNode()
    yield wait(100)
    appState.updateStepThumbnail()
  })

  dettachFromScene(_node, _stepObject) {
    // no-op
  }

  onBeforeTransition(node, _originState = {}, targetState = {}) {
    const { animator } = rootStore
    if (node.isEnabled() && targetState.hidden) {
      animator.fadeModel(node, node.visibility)
    } else if (!node.isEnabled() && !targetState.hidden) {
      animator.fadeModel(node, 0)
      node.setEnabled(true)
    }
  }

  onAfterTransition(node, _originState, targetState = {}) {
    if (targetState.hidden) node.setEnabled(false)
  }

  applyToNode(node, _originState = {}, targetState = {}, refState, t = 1) {
    const { animator } = rootStore
    const { wasEnabled } = refState
    if (wasEnabled && targetState.hidden) {
      animator.fadeModel(node, 1 - t)
    } else if (!wasEnabled && !targetState.hidden) {
      animator.fadeModel(node, t)
    }
  }

  resetToOriginal(node, _originState = {}, _targetState = {}) {
    node.setEnabled(true)
  }

  getRefState(node, _originalState, _targetState) {
    return { wasEnabled: node.isEnabled() }
  }
}

export default new RemoveTool()
