import { TransformNode } from '@babylonjs/core/Meshes/transformNode'
import { Vector3, Color3 } from '@babylonjs/core/Maths/math'
import rootStore from '~/src/app/store'
import { indexOf, reduce, forEach, filter, remove, pull } from 'lodash'

export const RED = new Color3(225 / 255, 36 / 255, 36 / 255)
export const GREEN = new Color3(31 / 255, 229 / 255, 35 / 255)
export const BLUE = new Color3(0, 95 / 255, 255 / 255)

export function isRootNode(node) {
  return node.id === '__root__' || !node.parent
}

export function getRootAncestor(node) {
  if (isRootNode(node)) return node
  else return getRootAncestor(node.parent)
}

export function getNodeAncestors(node) {
  const ancestors = []
  let ancestor = node
  while (ancestor !== null) {
    if (!isIgnoredNode(ancestor) && !isWrapperNode(ancestor)) {
      ancestors.push(ancestor)
    }
    ancestor = ancestor.parent
  }
  // the first ancestor is the AssetContainer root mesh
  ancestors.pop()
  return ancestors
}

export function getObjectIdFromNode(node) {
  const { modelRepository } = rootStore
  const rootNode = getRootAncestor(node)
  return modelRepository.findIdForNode(rootNode)
}

export function getDescendants(rootNode) {
  return rootNode._descendants || rootNode.getDescendants()
}

export function getNodeIdx(node) {
  // NOTE: I'm *ASSUMING* that the order of getDescendants() is *CONSISTENT*
  const rootNode = getRootAncestor(node)
  const descendants = getDescendants(rootNode)
  return indexOf(descendants, node)
}

export function getCustomProperties(node) {
  return node?.metadata?.gltf?.extras || {}
}

export function isDefaultSelectionNode(node) {
  const props = getCustomProperties(node)
  return props['aucta-default-selection'] !== undefined
}

export function isIgnoredNode(node) {
  const props = getCustomProperties(node)
  return props['aucta-ignore-node'] !== undefined
}

export function isPrimitive(node) {
  return /(.+)_primitive\d+/.test(node.name)
}

export function getPrimitiveAncestor(node) {
  const ancestors = getNodeAncestors(node)
  const match = node.name.match(/(.+)_primitive\d+/)
  if (match) {
    return ancestors.find(a => a.name === match[1])
  }
}

export function getDefaultSelectionAncestor(node) {
  const ancestors = getNodeAncestors(node)
  return ancestors.find(isDefaultSelectionNode)
}

export function findNode(rootNode, idx) {
  const descendants = getDescendants(rootNode)
  return descendants[idx]
}

export function isInstancedMesh(node) {
  return node.getClassName() === 'InstancedMesh'
}

export function getHighestInstancedAncestor(node) {
  return reduce(
    getNodeAncestors(node),
    (acc, ancestor) => {
      return isInstancedMesh(ancestor) ? ancestor : acc
    },
    node,
  )
}

export function getNodePath(node) {
  return isRootNode(node) ? [] : ['nodes', getNodeIdx(node)]
}

export function replaceInstancesWithClones(container, rootNode) {
  // TODO: this doesn't work, but I don't know why
  // const topLevelInstances = filter(
  //   instancedMeshes,
  //   m => (getHighestInstancedAncestor(m) === m)
  // )
  // console.log(instancedMeshes, topLevelInstances)
  // forEach(topLevelInstances, childMesh => {
  //   if (isInstancedMesh(childMesh)) {
  //     const id = childMesh.id
  //     const clone = childMesh.sourceMesh.clone(
  //       childMesh.name,
  //       childMesh.parent
  //     )
  //     clone.setParent(childMesh.parent)
  //     clone.position.copyFrom(childMesh.position)
  //     clone.rotation.copyFrom(childMesh.rotation)
  //     clone.scaling.copyFrom(childMesh.scaling)
  //     clone.name = childMesh.name
  //     clone.setEnabled(false)
  //     childMesh.setParent(null)
  //     childMesh.name = "<deleted>"
  //     console.log(container.meshes.length)
  //     pull(container.meshes, childMesh)
  //     console.log(container.meshes.length)
  //     // clone.id = id
  //   }
  // })
  // remove(rootNode.meshes, isInstancedMesh)
}

const TARGET_CUBE_SIZE = 2

export function autoScaleModel(model) {
  let { min, max } = model.getHierarchyBoundingVectors()
  const diagonal = Vector3.Distance(min, max)
  const scale = (TARGET_CUBE_SIZE * 2) / diagonal
  console.log('auto-scale>', scale)
  model.scaling = new Vector3(scale, scale, scale)
  return scale
}

export function getRotation(node) {
  return node.rotationQuaternion
    ? node.rotationQuaternion.toEulerAngles()
    : node.rotation.clone()
}

const containerWrappers = new Map()
const transformWrappers = new Map()
const pivotWrappers = new Map()

export function maybeGenerateNodeWrappers(node) {
  if (containerWrappers.has(node)) return
  const container = new TransformNode(`container-wrapper-${node.name}`)
  container.parent = node.parent
  container.isPickable = false
  container.position.copyFrom(node.position)
  container.scaling.copyFrom(node.scaling)
  container.rotation.copyFrom(getRotation(node))
  containerWrappers.set(node, container)
  const transform = new TransformNode(`transform-wrapper-${node.name}`)
  transform.isPickable = false
  transform.parent = container
  transformWrappers.set(node, transform)
  const pivot = new TransformNode(`pivot-wrapper-${node.name}`)
  pivot.isPickable = false
  pivot.parent = transform
  node.parent = pivot
  node.position = Vector3.Zero()
  node.rotation = Vector3.Zero()
  node.scaling = Vector3.One()
  pivotWrappers.set(node, pivot)
}

export function getContainerWrapper(node) {
  maybeGenerateNodeWrappers(node)
  return containerWrappers.get(node)
}

export function getTransformWrapper(node) {
  maybeGenerateNodeWrappers(node)
  return transformWrappers.get(node)
}

export function getPivotWrapper(node) {
  maybeGenerateNodeWrappers(node)
  return pivotWrappers.get(node)
}

export function isWrapperNode(node) {
  return (
    [...containerWrappers.values()].includes(node) ||
    [...transformWrappers.values()].includes(node) ||
    [...pivotWrappers.values()].includes(node)
  )
}

export function nuke(node) {
  if (!node) return
  node.setEnabled(false)
  node.parent = null
  node.getScene().removeMesh(node)
  node.dispose()
}
