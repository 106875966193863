import { createContext, useContext } from 'react'
import AuthStore from '~/src/features/auth/store'
import PersistenceStore from '~/src/features/persistence/store'
import TrainingStore from '~/src/features/training/store'
import KVStore from '~/src/features/kvstore/store'
import SceneManagerStore from '~/src/features/sceneManager/store'
import AnimatorStore from '~/src/features/animator/store'
import PlayerStore from '~/src/features/player/store'
import CameraStore from '~/src/features/camera/store'
import ScreenshotsStore from '~/src/features/screenshots/store'
// new stores
import ModelGalleryStore from '~/src/features/modelGallery/store'
import ModelRepositoryStore from '~/src/features/modelRepository/store'
import ToolbarStore from '~/src/features/toolbar/store'
import TransitionsStore from '~/src/features/transitions/store'
import LabelsStore from '~/src/features/labels/store'
import UndoStore from '~/src/features/undo/store'
import AppState from './appState'
// debug
import { toJS } from 'mobx'

const rootStore = {}
// app state
rootStore.appState = new AppState(rootStore)
// based on poc modules
rootStore.auth = new AuthStore(rootStore)
rootStore.persistence = new PersistenceStore(rootStore)
rootStore.training = new TrainingStore(rootStore)
rootStore.ui = new KVStore(rootStore)
rootStore.sceneManager = new SceneManagerStore(rootStore)
rootStore.camera = new CameraStore(rootStore)
rootStore.screenshots = new ScreenshotsStore(rootStore)
// new stores
rootStore.modelRepository = new ModelRepositoryStore(rootStore)
rootStore.modelGallery = new ModelGalleryStore(rootStore)
rootStore.toolbar = new ToolbarStore(rootStore)
rootStore.animator = new AnimatorStore(rootStore)
rootStore.player = new PlayerStore(rootStore)
rootStore.transitions = new TransitionsStore(rootStore)
rootStore.labels = new LabelsStore(rootStore)
rootStore.undo = new UndoStore(rootStore)

// for debugging
window.rootStore = rootStore
window.toJS = toJS
rootStore.toJSON = () => ({
  training: rootStore.training.toJSON(),
  modelGallery: rootStore.modelGallery.toJSON(),
})

export default rootStore

// React integration

const StoreContext = createContext(undefined)

export const StoreProvider = ({ children }) => (
  <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
)

export const useStore = () => {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useStore must be used within StoreProvider')
  }
  return context
}
