import positionTool from './position/positionTool'
import rotationTool from './rotation/rotationTool'
import scaleTool from './scale/scaleTool'
import highlightTool from './highlight/highlightTool'
import labelTool from './label/labelTool'
import removeTool from './remove/removeTool'

const toolDictionary = {
  [positionTool.key]: positionTool,
  [rotationTool.key]: rotationTool,
  [scaleTool.key]: scaleTool,
  [highlightTool.key]: highlightTool,
  [labelTool.key]: labelTool,
  [removeTool.key]: removeTool,
}

export default {
  get: key => toolDictionary[key],
}
